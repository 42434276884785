/* eslint-disable */
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormHandles, useField } from "@unform/core";
import { FiAlertCircle } from "react-icons/fi";
import { IconBaseProps } from "react-icons";

import api from "../../services/api";

import * as Styled from "./styles";
import {
  CompanyFromGetCompanies,
  DeliverymanFromGetDeliverymans,
} from "../../@types/customTypes";
import {
  generateSearchVariations,
  normalizePhoneNumber,
  normalizeString,
} from "../../utils/fomartSearchs";

interface Option {
  value: number;
  option: string;
}

interface CompanyOption {
  value: number;
  option: string;
  companyCityId: number;
  companyLat: string;
  companyLng: string;
}

interface DeliverymanFromGetDeliverymansApiResponse {
  current_page: number;
  data: DeliverymanFromGetDeliverymans[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: null | string;
  to: number;
  total: number;
}

interface Cities {
  id: string;
  name: string;
  state_id: string;
}

interface CitiesFromGetApiResponse {
  data: Cities[];
}

interface GetCompaniesApiResponse {
  current_page: number;
  data: CompanyFromGetCompanies[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: null | string;
  to: number;
  total: number;
}

interface LoocalInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  index?: number;
  formRef: React.RefObject<FormHandles>;
  childrenStateUpdater?: number;
  type: string;
  endpoint: "deliveryman" | "company" | "city";
}

const LoocalAutocompleteInput: React.FC<LoocalInputProps> = ({
  icon: Icon,
  name,
  formRef,
  index,
  childrenStateUpdater,
  placeholder,
  type,
  endpoint,
  ...rest
}) => {
  // eslint-disable-next-line
  const [options, setOptions] = useState<any>(null);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [debounceTimer, setDebounceTimer] = useState<null | NodeJS.Timeout>(
    null,
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { fieldName, registerField, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (isOptionsVisible) {
      const handleClickOutside = (event: MouseEvent) => {
        if (!containerRef.current?.contains(event.target as HTMLElement)) {
          setIsOptionsVisible(false);

          if (!selectedOption) {
            formRef.current?.setData({
              [name]: "",
            });
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
    return undefined;
  }, [inputRef, isOptionsVisible, formRef, name, selectedOption]);

  const handleSearchCompany = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (selectedOption) {
        setSelectedOption(null);
      }
      try {
        const { data: SearchResultData } =
          await api.get<GetCompaniesApiResponse>(
            `companies?search=fantasy_name:${e.target.value}`,
          );

        const foundOptions = SearchResultData.data
          .map((result, optionIndex: number) => {
            if (optionIndex < 6) {
              return {
                value: result.id,
                option: `${result.id} - ${result.fantasy_name}`,
                companyCityId: result.address.city_id,
                companyLat: result.address.latitude,
                companyLng: result.address.longitude,
              };
            }
            return false;
          })
          .filter((result) => result !== false);

        if (foundOptions[0]) {
          setOptions(foundOptions as CompanyOption[]);
        } else {
          setOptions(null);
        }
      } catch (err) {
        // tratar
      }
    },
    [selectedOption],
  );

  const handleSearchCity = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (selectedOption) {
        setSelectedOption(null);
      }
      try {
        const { data: SearchResultData } =
          await api.get<CitiesFromGetApiResponse>(
            `cities-by-name/${e.target.value}`,
          );

        const foundOptions = SearchResultData.data
          .map((result, optionIndex: number) => {
            if (optionIndex < 6) {
              return {
                value: result.id,
                option: `${result.id} - ${result.name}`,
              };
            }
            return false;
          })
          .filter((result) => result !== false);

        if (foundOptions[0]) {
          setOptions(foundOptions as unknown as Option[]);
        } else {
          setOptions(null);
        }
      } catch (err) {
        // tratar
      }
    },
    [selectedOption],
  );

  const handleSearchDeliveryman = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (selectedOption) {
        setSelectedOption(null);
      }
      try {
        const searchValue = normalizeString(e.target.value);
        const searchVariations = generateSearchVariations(searchValue);
        const normalizedPhone = normalizePhoneNumber(searchValue);

        const searchResultsPromises = searchVariations.map((variation) =>
          api.get<DeliverymanFromGetDeliverymansApiResponse>(
            `deliverymans?search=user.name:${variation};user.email:${variation};phones.number:${normalizedPhone};cpf:${normalizedPhone}`,
          ),
        );

        const searchResults = await Promise.all(searchResultsPromises);
        const combinedResults = searchResults.flatMap(
          (result) => result.data.data,
        );

        const uniqueResults = new Map();
        combinedResults.forEach((result) => {
          uniqueResults.set(result.id, result);
        });

        const foundOptions = Array.from(uniqueResults.values())
          .slice(0, 6)
          .map((result) => ({
            value: result.id,
            option: `${result.id} - ${result.user.name}`,
          }));

        if (foundOptions.length > 0) {
          setOptions(foundOptions as Option[]);
        } else {
          setOptions(null);
        }
      } catch (err) {
        // handle error
        console.error(err);
      }
    },
    [selectedOption],
  );

  const debounceSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      e.persist();
      const newDebounceTimer = setTimeout(() => {
        switch (endpoint) {
          case "company":
            handleSearchCompany(e);
            break;
          case "deliveryman":
            handleSearchDeliveryman(e);
            break;
          case "city":
            handleSearchCity(e);
            break;
          default:
            break;
        }
      }, 500);

      setDebounceTimer(newDebounceTimer);
    },
    [
      debounceTimer,
      handleSearchCompany,
      endpoint,
      handleSearchDeliveryman,
      handleSearchCity,
    ],
  );

  const handleSetListVisibility = useCallback(() => {
    setIsOptionsVisible((state) => !state);

    clearError();
  }, [clearError]);

  const handleChooseOption = useCallback(
    (option) => {
      setSelectedOption(option.value);
      formRef.current?.setData({
        [`${endpoint}_name`]: option.option,
        [`${endpoint}_id`]: option.value,
        company_city_id: option.companyCityId,
        company_latitude: option.companyLat,
        company_longitude: option.companyLng,
      });
    },
    [formRef, endpoint],
  );

  return (
    <Styled.Container
      ref={containerRef}
      isErrored={!!error}
      onClick={handleSetListVisibility}
    >
      {Icon && <Icon size={20} />}
      <Styled.Input
        placeholder=" "
        ref={inputRef}
        defaultValue=""
        {...rest}
        onChange={debounceSearch}
        autoComplete="off"
      />
      {isOptionsVisible === true && options && (
        <Styled.Options type={type}>
          <ul>
            {options.map((option: Option | CompanyOption) => (
              <li key={option.value}>
                <option onClick={() => handleChooseOption(option)}>
                  {option.option}
                </option>
              </li>
            ))}
          </ul>
        </Styled.Options>
      )}
      <label htmlFor={rest.id}>{placeholder}</label>
      {error && (
        <Styled.Error title={error}>
          <FiAlertCircle size={20} />
        </Styled.Error>
      )}
    </Styled.Container>
  );
};

export default LoocalAutocompleteInput;
