import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import Backoffice from "../pages/Backoffice";
import Dashboard from "../pages/Dashboard";
import Start from "../pages/Start";
import Orders from "../pages/Orders";
import NewOrder from "../pages/NewOrder";
import Forgot from "../pages/Forgot";
import Terms from "../pages/Terms";
import DeliverymansFinances from "../pages/DeliverymansFinances";
import EstablishmentsFinances from "../pages/EstablishmentsFinances";
import PerCity from "../pages/PerCity";
import Operators from "../pages/Operators";
import Deliverymans from "../pages/Deliverymans";
import Establishments from "../pages/Establishments";
import DeliverymansById from "../pages/DeliverymansById";
import EstablishmentsById from "../pages/EstablishmentsById";
import EstablishmentsNew from "../pages/EstablishmentsNew";
import OperatorsNew from "../pages/OperatorsNew";
import Bankly from "../pages/Bankly";
import DispatchOrders from "../pages/DispatchOrders";
import Contact from "../pages/Contact";
import Tables from "../pages/Tables";
import DeliverymanPrivacyPolicy from "../pages/DeliverymanPrivacyPolicy";
import NotFound from "../pages/NotFound";
import DeliverymansNew from "../pages/DeliverymansNew";
import OperatorsById from "../pages/OperatorsById";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={Backoffice} />
      <Route exact path="/signin" component={Backoffice} />
      <Route exact path="/backoffice" component={Backoffice} />
      <Route exact path="/termos" component={Terms} />
      <Route exact path="/recuperar" component={Forgot} />
      <Route exact path="/fale-conosco" component={Contact} />
      <Route exact path="/dashboard" component={Dashboard} isPrivate />
      <Route exact path="/start" component={Start} isPrivate />
      <Route exact path="/pedidos" component={Orders} isPrivate />
      <Route exact path="/whatsapp" component={Orders} isPrivate />
      <Route exact path="/despachos" component={DispatchOrders} isPrivate />
      <Route exact path="/novo-pedido" component={NewOrder} isPrivate />

      <Route
        exact
        path="/financeiro/entregadores"
        component={DeliverymansFinances}
        isPrivate
        isAdmin
        isFinancial
      />
      <Route
        exact
        path="/financeiro/estabelecimentos"
        component={EstablishmentsFinances}
        isPrivate
        isAdmin
        isFinancial
      />
      <Route
        exact
        path="/gerenciar/por-cidade"
        component={PerCity}
        isPrivate
        isAdmin
        isOperator
      />
      <Route
        exact
        path="/gerenciar/tabelas"
        component={Tables}
        isPrivate
        isAdmin
      />
      <Route
        exact
        path="/gerenciar/operadores"
        component={Operators}
        isPrivate
        isAdmin
        isOperator
      />
      <Route
        exact
        path="/gerenciar/novo-operador"
        component={OperatorsNew}
        isPrivate
        isAdmin
        isOperator
      />
      <Route
        exact
        path="/gerenciar/operadores/:id"
        component={OperatorsById}
        isPrivate
        isAdmin
        isOperator
      />
      <Route
        exact
        path="/gerenciar/entregadores"
        component={Deliverymans}
        isPrivate
        isAdmin
        isOperator
      />
      <Route
        exact
        path="/gerenciar/novo-entregador"
        component={DeliverymansNew}
        isPrivate
        isAdmin
        isOperator
      />
      <Route
        exact
        path="/gerenciar/entregadores/:id"
        component={DeliverymansById}
        isPrivate
        isAdmin
        isOperator
      />
      <Route
        exact
        path="/gerenciar/estabelecimentos"
        component={Establishments}
        isPrivate
        isAdmin
        isOperator
      />
      <Route
        exact
        path="/gerenciar/novo-estabelecimento"
        component={EstablishmentsNew}
        isPrivate
        isAdmin
        isOperator
      />
      <Route
        exact
        path="/gerenciar/estabelecimentos/:id"
        component={EstablishmentsById}
        isPrivate
        isAdmin
        isOperator
      />
      <Route exact path="/bankly" component={Bankly} isAdmin />
      <Route
        exact
        path="/entregadores/politica-privacidade"
        component={DeliverymanPrivacyPolicy}
      />
      <Route path="*" exact component={NotFound} />
    </Switch>
  );
};

export default Routes;
