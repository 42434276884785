import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";
import api from "../../services/api";
import Modal from "../Modal";
import DeliverymanMap from "../DeliverymanMap";
import {
  formatCurrencyByText,
  phoneFormat,
} from "../../utils/inputAndTextMasks";
import getPaymentMethodById from "../../utils/getPaymentMethodById";
import { formatDateToSaoPaulo } from "../../utils/formatLocale";
import { Order } from "../../@types/customTypes";
import * as Styled from "./styles";
import Button from "../Button";
import { statusNameType } from "../../utils/statusNameType.js";
import apiV3 from "../../services/apiv3";

interface ModalProps {
  currentOrder: Order;
  setCurrentOrder: React.Dispatch<React.SetStateAction<Order | null>>;
  handleCloseModal: () => void;
  isModalOpen: boolean;
}

const DetailsModal: React.FC<ModalProps> = ({
  handleCloseModal,
  currentOrder,
  setCurrentOrder,
  isModalOpen,
}) => {
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPass = useCallback(async () => {
    const { value: token, isConfirmed } = await Swal.fire({
      title: "Digite o código de confirmação do iFood",
      html: `<input type="text" id="tokenInput" class="swal2-input" placeholder="Código" style="max-width: 18em;" oninput="this.value = this.value.replace(/[^0-9]/g, '')" />`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "var(--color-primary)",
      cancelButtonColor: "var(--color-danger)",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      preConfirm: () => {
        const tokenValue =
          (document.getElementById("tokenInput") as HTMLInputElement)?.value ||
          "";
        if (!tokenValue) {
          Swal.showValidationMessage("O código é obrigatório");
        }
        return tokenValue;
      },
    });

    if (isConfirmed && token) {
      setIsLoading(true);
      try {
        const data = {
          code: token,
          order_id: String(currentOrder.id),
        };

        await api.post("/ifood/verify-code", data);
        addToast("Código validado com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (e) {
        addToast("Código inválido. Por favor, verifique e tente novamente.", {
          appearance: "error",
          autoDismiss: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [addToast, currentOrder.id]);

  const getOrder = useCallback(async () => {
    try {
      const { data } = await apiV3.get(
        `deliveries?&order_id=${currentOrder.id}`,
      );

      if (data.data.length > 0 && data.data[0].orders.length > 0) {
        const updatedOrder = {
          ...data.data[0].orders[0],
          deliveryman: data.data[0].deliveryman || null,
        };

        setCurrentOrder(updatedOrder);
      }
    } catch (e) {
      addToast(
        "Ocorreu um erro ao carregar dados, entre em contato com o suporte!",
        {
          appearance: "warning",
          autoDismiss: true,
        },
      );
    }
  }, [addToast, currentOrder.id, setCurrentOrder]);

  const deliverymanLocation = useMemo(
    () =>
      currentOrder.deliveryman
        ? [
            Number(currentOrder.deliveryman?.latitude),
            Number(currentOrder.deliveryman?.longitude),
          ]
        : [],
    [currentOrder.deliveryman],
  );

  const companyLocation = useMemo(
    () => [
      Number(currentOrder.company?.address?.latitude),
      Number(currentOrder.company?.address?.longitude),
    ],
    [currentOrder.company?.address],
  );

  const customerLocation = useMemo(
    () => [
      Number(currentOrder.customer?.address?.latitude),
      Number(currentOrder.customer?.address?.longitude),
    ],
    [currentOrder.customer?.address],
  );

  useEffect(() => {
    const reloadOrderInterval = setInterval(() => {
      getOrder();
    }, 5 * 1000);

    return () => {
      clearInterval(reloadOrderInterval);
    };
  }, [addToast, getOrder]);

  return (
    <Modal
      setIsOpen={handleCloseModal}
      isOpen={isModalOpen}
      customStyles={{
        maxWidth: "unset",
        padding: "0",
        height: "80%",
        width: "80%",
      }}
    >
      <Styled.Container>
        {currentOrder && (
          <>
            <DeliverymanMap
              deliverymanLocation={deliverymanLocation}
              customerLocation={customerLocation}
              companyLocation={companyLocation}
              isOrderFinished={
                statusNameType(currentOrder.order_status_id) === "Entregue"
              }
            />
            <Styled.DetailsContainer>
              <Styled.Info>
                <div>
                  {statusNameType(currentOrder.order_status_id) ===
                    "Entregue" && (
                    <>
                      <div>
                        Entregue por{" "}
                        <Styled.Strong>
                          {currentOrder.deliveryman?.nickname}
                        </Styled.Strong>{" "}
                        em{" "}
                        <Styled.Strong>
                          {formatDateToSaoPaulo(currentOrder.updated_at)}
                        </Styled.Strong>
                      </div>
                      <br />
                    </>
                  )}
                  Cliente:{" "}
                  <Styled.Strong>{currentOrder.customer.name}</Styled.Strong>
                  {" - "}
                  Telefone:{" "}
                  <Styled.Strong>
                    {phoneFormat(currentOrder.customer.phones[0])}
                  </Styled.Strong>
                  <br />
                  <br />
                  Endereço:{" "}
                  <Styled.Strong>
                    {currentOrder.customer.address.address}
                    {currentOrder.customer.address.number &&
                      ` - ${currentOrder.customer.address.number}`}
                    {currentOrder.customer.address.complement &&
                      ` (${currentOrder.customer.address.complement})`}
                  </Styled.Strong>
                  <>
                    {" - "}
                    Distância:{" "}
                    <Styled.Strong>
                      {currentOrder.distance / 1000} KM
                    </Styled.Strong>
                  </>
                  <br />
                  <br />
                  CEP:
                  <Styled.Strong>
                    {" "}
                    {currentOrder.customer.address.postal_code}
                  </Styled.Strong>
                  {" - "}Bairro:{" "}
                  <Styled.Strong>
                    {currentOrder.customer.address.neighborhood}
                  </Styled.Strong>
                  {" - "} Cidade:{" "}
                  <Styled.Strong>
                    {currentOrder.customer.address.city.name}
                  </Styled.Strong>
                  <br />
                  <br />
                  Tipo do pedido: <Styled.Strong>Normal</Styled.Strong> - Método
                  de pagamento:{" "}
                  <Styled.Strong>
                    {getPaymentMethodById(currentOrder.payment_type_id)}
                  </Styled.Strong>
                  <br />
                  <br /> Valor do pedido:
                  <Styled.Strong>
                    {" "}
                    {formatCurrencyByText(currentOrder.amount)}
                  </Styled.Strong>{" "}
                  - Troco:{" "}
                  <Styled.Strong>
                    {formatCurrencyByText(currentOrder.change)}
                  </Styled.Strong>{" "}
                  - Taxa de Entrega:{" "}
                  <Styled.Strong>
                    {formatCurrencyByText(
                      Number(currentOrder.delivery_cost) +
                        Number(currentOrder.loocal_fee),
                    )}
                  </Styled.Strong>
                  <>
                    {" "}
                    - Taxa da Loocal:{" "}
                    <Styled.Strong>
                      {" "}
                      {formatCurrencyByText(currentOrder.loocal_fee)}
                    </Styled.Strong>{" "}
                  </>
                  <br />
                  <br />O pedido{" "}
                  <Styled.Strong>
                    {currentOrder.thermal_box ? "precisará " : "não precisará"}
                  </Styled.Strong>{" "}
                  de caixa térmica,{" "}
                  <Styled.Strong>
                    {currentOrder.return ? "precisará " : "não precisará"}
                  </Styled.Strong>{" "}
                  retornar e{" "}
                  <Styled.Strong>
                    {currentOrder.get_sign ? "precisará " : "não precisará"}
                  </Styled.Strong>{" "}
                  de assinatura.
                  {currentOrder.observations[0] && (
                    <>
                      <br />
                      <br />
                      Observações:{" "}
                      <Styled.Strong>
                        {currentOrder.observations[0]}
                      </Styled.Strong>
                    </>
                  )}
                  {currentOrder.ifood_order ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      <div style={{ width: "20%" }}>
                        <Button
                          disabled={isLoading}
                          title="Inserir código ifood"
                          onClick={handleResetPass}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </Styled.Info>
            </Styled.DetailsContainer>
          </>
        )}
      </Styled.Container>
    </Modal>
  );
};

export default React.memo(DetailsModal);
