import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiEdit, FiRefreshCw, FiTrash2 } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
import { FormHandles } from "@unform/core";
import Swal from "sweetalert2";

import { useSidebar } from "../../hooks/SidebarContext";

import BorderlessInput from "../../components/BorderlessInput";
import Sidebar from "../../components/Sidebar";
import Skeleton from "../../components/Skeleton";
import Pagination from "../../components/Pagination";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";

import { formatLocaleSP } from "../../utils/formatLocale";

import * as Styled from "./styles";
import apiV3 from "../../services/apiv3";

export interface OperatorsFromGetOperators {
  id: number;
  name: string;
  email: string;
  roles: string[];
  created_at: string;
  cities: { id: number; name: string; state_id: number }[];
}

interface OperatorsApiResponse {
  operators: OperatorsFromGetOperators[];
  totalItems: number;
  totalPages: number;
  currentPage: number;
}

const Operators: React.FC = () => {
  const [operators, setOperators] = useState<
    OperatorsFromGetOperators[] | null
  >(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [debounceTimer, setDebounceTimer] = useState<null | NodeJS.Timeout>(
    null,
  );

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const formRef = useRef<FormHandles>(null);

  const handleGetOperators = useCallback(
    async (size = 8, page = 1) => {
      try {
        setIsLoading(true);

        const { data: operatorsResponse } =
          await apiV3.get<OperatorsApiResponse>(
            `operators?limit=${size}&page=${page}`,
          );

        setCurrentPage(page);
        setTotalPages(operatorsResponse.totalPages);
        setOperators(operatorsResponse.operators);
        setIsLoading(false);
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast],
  );

  const debounceSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      e.persist();
      const newDebounceTimer = setTimeout(() => {
        handleGetOperators(8, 1);
      }, 500);
      setDebounceTimer(newDebounceTimer);
    },
    [debounceTimer, handleGetOperators],
  );

  useEffect(() => {
    handleGetOperators();
  }, [handleGetOperators]);

  return (
    <Container>
      <Sidebar />

      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.OperatorsContainer>
          <Styled.Title>
            <div>
              Gerenciar - Operadores{" "}
              <FiRefreshCw size={20} onClick={() => handleGetOperators(8, 1)} />
            </div>
            <Styled.SearchForm ref={formRef} onSubmit={() => {}}>
              <Styled.TopLink to="/gerenciar/novo-operador">
                + Novo operador
              </Styled.TopLink>
              <BorderlessInput
                type="text"
                id="search"
                name="search"
                onChange={debounceSearch}
                placeholder="Pesquisar"
                autoComplete="off"
              />
            </Styled.SearchForm>
          </Styled.Title>
          <Styled.ListContainer>
            {isLoading ? (
              <Styled.OperatorsShimmer>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Styled.OperatorsShimmer>
            ) : (
              <Styled.Ul>
                {operators?.map((operator) => {
                  return (
                    <Styled.Li color="var(--color-primary)" key={operator.id}>
                      <Styled.CommonField>
                        <span>ID:</span>
                        <strong>{operator.id}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Nome Fantasia:</span>
                        <strong>{operator.name}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Email:</span>
                        <strong>{operator.email}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Permissão:</span>
                        <strong>
                          {operator.roles.map((role) => role).join(", ")}
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Criado em:</span>
                        <strong>
                          {formatLocaleSP(
                            operator.created_at,
                            "dd/MM/yyyy 'às' HH:mm",
                          )}
                        </strong>
                      </Styled.CommonField>
                      <Styled.ActionField style={{ gap: "12px" }}>
                        <Styled.Link to={`operadores/${operator.id}`}>
                          <FiEdit size={24} />
                        </Styled.Link>
                        <Styled.Link
                          to="#"
                          onClick={async (e) => {
                            e.preventDefault();
                            const { isConfirmed } = await Swal.fire({
                              title: "Tem certeza?",
                              text: `Tem certeza que deseja excluir o(a) operador(a) ${operator.name}?`,
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "var(--color-primary)",
                              cancelButtonColor: "var(--color-danger)",
                              confirmButtonText: "Sim",
                              cancelButtonText: "Não",
                            });

                            if (isConfirmed) {
                              try {
                                await apiV3.delete(`operators/${operator.id}`);
                                addToast("Operador excluído com sucesso!", {
                                  appearance: "success",
                                  autoDismiss: true,
                                });
                                handleGetOperators(8, 1);
                              } catch (error) {
                                addToast("Erro ao excluir operador!", {
                                  appearance: "error",
                                  autoDismiss: true,
                                });
                              }
                            }
                          }}
                        >
                          <FiTrash2 size={24} />
                        </Styled.Link>
                      </Styled.ActionField>
                    </Styled.Li>
                  );
                })}
              </Styled.Ul>
            )}

            {currentPage !== 0 && (
              <Pagination
                updateList={handleGetOperators}
                totalPages={totalPages}
                currentPage={currentPage}
              />
            )}
          </Styled.ListContainer>
        </Styled.OperatorsContainer>
      </ContainerMain>
    </Container>
  );
};

export default Operators;
