import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import {
  FiArrowLeft,
  FiAtSign,
  FiBookmark,
  FiLock,
  FiNavigation,
  FiSettings,
  FiUnlock,
  FiUser,
} from "react-icons/fi";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";

import { useSidebar } from "../../hooks/SidebarContext";

import api from "../../services/api";

import Sidebar from "../../components/Sidebar";
import Input from "../../components/Input";
import Button from "../../components/Button";
import LoadingSpinner from "../../components/LoadingSpinner";
import Select from "../../components/ReactSelect";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";

import getValidationErrors from "../../utils/getValidationErrors";

import * as Styled from "./styles";
import apiV3 from "../../services/apiv3";

interface StatesResponse {
  id: number;
  name: string;
  uf: string;
}

interface CitiesResponse {
  id: number;
  state_id: number;
  name: string;
}

interface OperatorFormData {
  email: string;
  name: string;
  role: string;
  state_ids: string[];
  cities: string | string[];
  password: string;
  password_confirmation: string;
}

const OperatorsNew: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [cities, setCities] = useState<{ label: string; value: string }[]>([]);
  const [states, setStates] = useState<{ label: string; value: string }[]>([]);
  const [roles, setRoles] = useState<{ label: string; value: string }[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedStates, setSelectedStates] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedCities, setSelectedCities] = useState<
    { label: string; value: string }[]
  >([]);

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const formRef = useRef<FormHandles>(null);

  const getCities = useCallback(async (state_ids: number[]) => {
    setShowSpinner(true);
    setCities([]);
    if (formRef.current) {
      formRef.current.setData({
        cities: [],
      });
    }

    const allCities: { label: string; value: string }[] = [];
    const citiesData = await Promise.all(
      state_ids.map(async (state_id) => {
        const { data: CitiesData } = await api.get(`/cities/${state_id}`);
        return CitiesData.map((city: CitiesResponse) => ({
          label: city.name,
          value: city.id.toString(),
        }));
      }),
    );
    citiesData.forEach((MappedCities) => {
      allCities.push(...MappedCities);
    });
    allCities.sort((a, b) => a.label.localeCompare(b.label));
    setCities(allCities);
    setShowSpinner(false);
  }, []);

  const handleStateChange = async (
    selectedOptions: { label: string; value: string }[],
  ) => {
    setSelectedStates(selectedOptions);
    if (selectedOptions && selectedOptions.length > 0) {
      getCities(selectedOptions.map((option) => Number(option.value)));
    } else {
      setCities([]);
      if (formRef.current) {
        formRef.current.setData({
          cities: [],
        });
      }
    }
  };

  const submitNewOperatorForm = useCallback(
    async (data: OperatorFormData) => {
      try {
        const dataToSubmitNewOperator = {
          user: {
            name: data.name,
            email: data.email,
            password: data.password,
          },
          cities: selectedCities.map((city) => city.value),
          roles: selectedRoles.map((role) => role.value),
        };

        await apiV3.post("operators", dataToSubmitNewOperator);

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Alterações realizadas com sucesso!",
        });
        setShowSpinner(false);
        setIsLoading(false);
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Não foi possível cadastrar o operador. Motivo: ${
            error.response.data ?? "Erro desconhecido"
          }`,
        });
        setShowSpinner(false);
        setIsLoading(false);
      }
    },
    [selectedCities, selectedRoles],
  );

  const verifyFormSubmit = useCallback(
    async (data: OperatorFormData) => {
      setShowSpinner(true);
      setIsLoading(true);
      try {
        // Extrair o primeiro cargo da lista de cargos selecionados
        const firstRole =
          selectedRoles.length > 0 ? selectedRoles[0].value : "";

        // Atualizar os dados do formulário com o primeiro cargo
        const updatedData = {
          ...data,
          role: firstRole,
        };

        const schema = Yup.object().shape({
          email: Yup.string()
            .email("Email inválido")
            .required("Campo obrigatório"),
          name: Yup.string().required("Campo obrigatório"),
          role: Yup.string().required("Campo obrigatório"),
          password: Yup.string().min(4, "No mínimo 4 digitos"),
          password_confirmation: Yup.string()
            .min(4, "No mínimo 4 digitos")
            .oneOf([Yup.ref("password")], "Confirmação inválida"),
        });
        await schema.validate(updatedData, { abortEarly: false });
        submitNewOperatorForm(updatedData);
      } catch (err) {
        setShowSpinner(false);
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [submitNewOperatorForm, selectedRoles],
  );

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const { data: rolesData } = await apiV3.get("/auth/roles");
        const mappedRoles = rolesData.map((role: { name: string }) => ({
          label: role.name,
          value: role.name,
        }));
        setRoles(mappedRoles);
      } catch (error) {
        addToast("Ocorreu um erro ao carregar os cargos!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    };

    fetchRoles();
  }, [addToast]);

  useEffect(() => {
    (async () => {
      setShowSpinner(true);
      try {
        const { data: StatesData } = await api.get("/states");
        const MappedStates = StatesData.map((state: StatesResponse) => ({
          label: state.name,
          value: state.id.toString(),
        }));
        setStates(MappedStates);
      } catch (e) {
        addToast("Ocorreu um erro ao carregar estados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
    })();
  }, [addToast]);

  return (
    <Container>
      <Sidebar />
      {showSpinner && <LoadingSpinner />}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.OperatorContainer>
          <Styled.Link to="/gerenciar/operadores">
            <FiArrowLeft size={20} />
            Voltar
          </Styled.Link>
          <Styled.Form
            onSubmit={verifyFormSubmit}
            ref={formRef}
            autoComplete="off"
          >
            <Styled.Strong>Dados do responsável</Styled.Strong>
            <Styled.Row>
              <Select
                id="role"
                name="role"
                placeholder="Cargo"
                formRef={formRef}
                isMulti
                options={roles}
                value={selectedRoles}
                onChange={(selectedOptions: any) =>
                  setSelectedRoles(
                    selectedOptions as { label: string; value: string }[],
                  )
                }
                icon={FiSettings}
              />

              <Input
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                icon={FiAtSign}
              />
            </Styled.Row>

            <Styled.Row>
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Nome"
                icon={FiUser}
              />
            </Styled.Row>

            <Styled.Row>
              <Select
                id="state_ids"
                name="state_ids"
                placeholder="Estados de atuação"
                formRef={formRef}
                isMulti
                options={states}
                value={selectedStates}
                onChange={handleStateChange}
                icon={FiBookmark}
                isSearchable
                closeMenuOnSelect={false}
              />
              <Select
                id="cities"
                name="cities"
                placeholder="Cidade de atuação"
                isMulti
                formRef={formRef}
                options={cities}
                value={selectedCities}
                onChange={(selectedOptions: any) => {
                  setSelectedCities(
                    selectedOptions as { label: string; value: string }[],
                  );
                }}
                icon={FiNavigation}
                isSearchable
                closeMenuOnSelect={false}
              />
            </Styled.Row>

            <Styled.Row>
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Senha"
                icon={FiUnlock}
              />

              <Input
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                placeholder="Confirme a senha"
                icon={FiLock}
              />
            </Styled.Row>

            <div>
              <Button
                type="submit"
                content={isLoading ? "Carregando..." : "Salvar dados"}
                disabled={isLoading}
              />
            </div>
          </Styled.Form>
        </Styled.OperatorContainer>
      </ContainerMain>
    </Container>
  );
};

export default OperatorsNew;
