/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";

import ReactModal from "react-modal";
import { MdClose } from "react-icons/md";

import * as Styled from "./styles";

interface ModalProps {
  isOpen: boolean;
  customStyles?: {
    maxWidth?: string;
    padding?: string;
    height?: string;
    width?: string;
    background?: string;
    overflow?: string;
    borderRadius?: string;
  };
  setIsOpen: () => void;
}

const Modal: React.FC<ModalProps> = ({
  children,
  customStyles,
  isOpen,
  setIsOpen,
}) => {
  const [modalStatus, setModalStatus] = useState(isOpen);

  ReactModal.setAppElement("#root");

  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "unset";
      };
    }
    return undefined;
  }, [isOpen]);

  const handleCloseModal = useCallback(() => {
    setIsOpen();
  }, [setIsOpen]);

  return (
    <ReactModal
      onRequestClose={handleCloseModal}
      closeTimeoutMS={100}
      isOpen={modalStatus}
      ariaHideApp={false}
      style={{
        content: {
          padding: !customStyles?.padding ? "32px" : customStyles.padding,
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          background: !customStyles?.background
            ? "#F0F0F5"
            : customStyles.background,
          color: "#000000",
          borderRadius: customStyles?.borderRadius
            ? customStyles?.borderRadius
            : "4px",
          width: !customStyles?.width ? "80vw" : customStyles.width,
          maxWidth: !customStyles?.maxWidth ? "592px" : customStyles.maxWidth,
          overflow: customStyles?.overflow ?? "auto",
          border: "none",
          maxHeight: "90vh",
          height: customStyles?.height ? customStyles.height : "unset",
        },
        overlay: {
          backgroundColor: "#121214e6",
          zIndex: 9,
        },
      }}
    >
      {children}
      <Styled.CloseModalBtn onClick={handleCloseModal}>
        <MdClose />
      </Styled.CloseModalBtn>
    </ReactModal>
  );
};

export default Modal;
