import React, { Dispatch, SetStateAction, useRef } from "react";
import { FormHandles } from "@unform/core";
import { useToasts } from "react-toast-notifications";
import { FiCheck, FiTruck } from "react-icons/fi";
import Modal from "../Modal";
import * as Styled from "./styles";
import LoocalAutocompleteInput from "../LoocalAutocompleteInput";
import { Order } from "../../@types/customTypes";
import apiV3 from "../../services/apiv3";

interface SelectDeliverymanModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
  order: Order;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  handleGetOrders: () => void;
}

const SelectDeliverymanModal: React.FC<SelectDeliverymanModalProps> = ({
  order,
  isOpen,
  onRequestClose,
  onConfirm,
  setIsLoading,
  isLoading,
  handleGetOrders,
}) => {
  const { addToast } = useToasts();
  const formRef = useRef<FormHandles>(null);

  const handleConfirm = async () => {
    const formData = formRef.current?.getData();
    const deliverymanName = formData?.deliveryman_name;
    const deliverymanId = parseFloat(deliverymanName.split("-")[0].trim());

    if (order && order.delivery_id && deliverymanId && deliverymanId > 0) {
      try {
        setIsLoading(true);
        await apiV3.put(`deliveries/${order.delivery_id}/deliveryman`, {
          deliveryman_id: deliverymanId,
        });

        setIsLoading(false);
        addToast("Pedido adicionado com sucesso!", {
          appearance: "success",
          autoDismiss: true,
        });
        formRef.current?.reset();
        onRequestClose();
        handleGetOrders();
      } catch (err) {
        const errorMessage =
          (err as any).response?.data?.message ||
          "Erro ao atrelar entregador, entre em contato com o suporte!";
        addToast(errorMessage, {
          appearance: "warning",
          autoDismiss: true,
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        setIsOpen={onRequestClose}
        isOpen={isOpen}
        customStyles={{
          maxWidth: "650px",
          padding: "30px",
          overflow: "visible",
        }}
      >
        <Styled.Container>
          <Styled.DetailsContainer>
            <Styled.Strong>
              {order.order_status_id === 1 ? "Atrelar" : "Realocar"} entregador
              | Pedido #{order.id}
            </Styled.Strong>
            <Styled.CommonField>
              <Styled.SearchForm ref={formRef} onSubmit={() => {}}>
                <Styled.GridForm>
                  {order.order_status_id === 1 ? (
                    <Styled.ContainerDiv>
                      <LoocalAutocompleteInput
                        type="normal"
                        id="deliveryman_name"
                        name="deliveryman_name"
                        placeholder="Entregador"
                        formRef={formRef}
                        endpoint="deliveryman"
                        icon={FiTruck}
                      />
                    </Styled.ContainerDiv>
                  ) : null}
                  <Styled.ContainerDiv>
                    <Styled.SearchButtonForm
                      type="button"
                      disabled={isLoading}
                      onClick={
                        order.order_status_id === 1 ? handleConfirm : onConfirm
                      }
                    >
                      {order.order_status_id === 1 ? "Confirmar" : "Realocar"}
                      {isLoading ? <Styled.SpinningIcon /> : <FiCheck />}
                    </Styled.SearchButtonForm>
                  </Styled.ContainerDiv>
                </Styled.GridForm>
              </Styled.SearchForm>
            </Styled.CommonField>
          </Styled.DetailsContainer>
        </Styled.Container>
      </Modal>
    </>
  );
};

export default SelectDeliverymanModal;
