import styled, { css } from "styled-components";
import { shade } from "polished";

import Tooltip from "../Tooltip";

interface InputContainer {
  isErrored: boolean;
}

interface OptionsContainerProps {
  type: string;
}

export const Container = styled.div<InputContainer>`
  position: relative;
  width: 100%;
  border: 1px solid rgba(220, 220, 220);
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: white;
  padding: 2px 0px;
  position: relative;
  min-width: 250px;
  min-height: 53px;
  cursor: default;

  > label {
    color: #8e8e8e;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    padding: 0 3px;
    left: 47px;
    transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
    -moz-transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  > input:not(:placeholder-shown) {
    & ~ label {
      position: absolute;
      top: -10px;
      left: 20px;
      background: white;
      font-size: 14px;
      color: #8e8e8e;
    }
  }

  > input:focus {
    & ~ label {
      position: absolute;
      top: -10px;
      left: 20px;
      background: white;
      font-size: 14px;
      color: #8e8e8e;
    }
  }

  ${({ isErrored }) =>
    !isErrored &&
    css`
      background-image: linear-gradient(45deg, transparent 50%, #8e8e8e 50%),
        linear-gradient(135deg, #8e8e8e 50%, transparent 50%);
      background-position: calc(100% - 25px) 24px, calc(100% - 20px) 24px;
      background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
      background-repeat: no-repeat;

      &:hover {
        background-image: linear-gradient(
            45deg,
            transparent 50%,
            var(--color-primary) 50%
          ),
          linear-gradient(135deg, var(--color-primary) 50%, transparent 50%);
      }
    `}

  ${({ isErrored }) =>
    isErrored &&
    css`
      > svg:first-child {
        stroke: #c53030;
        > path {
          stroke: #c53030;
        }
      }
      border-color: #c53030;
    `}

  &:hover {
    > svg:first-child {
      stroke: var(--color-primary) !important;
      color: var(--color-primary) !important;
      > path {
        stroke: var(--color-primary) !important;
      }
    }
    border-color: var(--color-primary);
  }

  > svg {
    margin: 0 16px;
    stroke: #8e8e8e;
    color: #8e8e8e;
    transition: opacity 0.3s;
    min-width: 20px;
    min-height: 20px;
    > path {
      stroke: #8e8e8e;
    }
  }
`;

export const Placeholder = styled.div`
  color: #8e8e8e;
  margin-right: 30px;
  max-height: 19px;
`;
export const Value = styled.div`
  color: #373737;
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  flex: 1;
  border: 0;
  background: transparent;
  outline: none;
  color: #373737;
  font-size: 16px;
  padding: 14px 0;

  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;

  &::placeholder {
    color: #8e8e8e;
  }
  &:-webkit-autofill {
    & ~ label {
      color: #8e8e8e;
    }
  }
`;

export const Options = styled.div<OptionsContainerProps>`
  position: absolute;
  min-width: 100%;
  left: 0;
  top: ${({ type }) => (type === "long" ? "-300%" : "calc(100% + 5px)")};
  background: white;
  padding: 5px 10px;
  box-shadow: 2px 2px 10px rgba(220, 220, 220, 0.2);
  border-radius: 5px;
  z-index: 5;
  color: var(--color-text-primary);
  > ul {
    > li {
      list-style: none;
      > option {
        font-size: 13px;
        padding: 5px 0;
        cursor: pointer;
        &:hover {
          color: ${shade(0.6, "#253451")};
        }
      }
    }
    > li:not(:last-child) {
      list-style: none;
      border-bottom: 1px solid rgba(220, 220, 220, 0.5);
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  position: absolute;
  right: 0;
  margin: 0 16px;
  > svg {
    stroke: #c53030;
    > path {
      stroke: #c53030;
    }
  }
`;
