import { lighten } from "polished";
import styled from "styled-components";
import { Link as ReactLink } from "react-router-dom";
import { Form as Unform } from "@unform/web";
import { FiLoader } from "react-icons/fi";

export const Container = styled.div``;

export const DetailsContainer = styled.div`
  > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const SearchButtonForm = styled.button`
  background-color: #466aa9;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;

  &:hover {
    background-color: #345b8e;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SearchForm = styled(Unform)`
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  width: 100%;
  gap: 5px;
  > div {
    width: 200px;
  }

  > select {
    width: 150px;
    outline: none;
    border: none;
    height: 33px;
    border-radius: 6px;
    padding: 0 4px;
  }

  @media (min-width: 620px) {
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
  }

  @media (min-width: 900px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const ContainerDiv = styled.div`
  width: 100% !important;
  display: flex;
`;

export const GridForm = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 100%;
  width: 100% !important;
  gap: 14px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const SpinningIcon = styled(FiLoader)`
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Strong = styled.p`
  font-weight: 500;
  font-size: 18px;
  padding-left: 15px;
  margin-bottom: 15px;
  color: #373737;
`;

export const CommonField = styled.div`
  display: flex;
  align-items: center;
  > p {
    color: #373737;
    margin-left: 50px;
    > strong {
      font-weight: 500;
    }
  }
`;

export const FieldTitle = styled.div`
  display: flex;
  align-items: center;
  min-width: 178px;

  > h3 {
    color: var(--color-text-secondary);
    margin-left: 8px;
  }
  > svg {
    color: var(--color-primary);
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  align-items: center;
  > p {
    color: #373737;
    margin-left: 50px;
    > strong {
      font-weight: 500;
    }
  }
`;

export const Image = styled.img`
  height: 130px;
  width: 130px;
  object-fit: cover;
`;

export const ImageContainer = styled.div`
  &:not(:last-child) {
    margin-right: 15px;
  }
`;

export const ImageTitle = styled.p`
  color: #373737;
  text-align: center;
`;

export const ActionContainer = styled.div`
  margin-top: 40px;

  display: flex;
  justify-content: center;

  > svg {
    margin-left: 15px;
  }

  > a > svg,
  svg {
    stroke: #373737;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      stroke: ${lighten(0.25, "#466aa9")};
    }
  }
`;

export const Link = styled(ReactLink)``;
