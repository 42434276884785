import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FiHome,
  FiFileText,
  FiFilePlus,
  FiMinimize2,
  FiMaximize2,
  FiDollarSign,
} from "react-icons/fi";
import { AiOutlinePoweroff } from "react-icons/ai";
import { RiUserSettingsLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";

import { useAuth } from "../../hooks/AuthContext";
import { useSidebar } from "../../hooks/SidebarContext";
import logoImg from "../../assets/img/logo-movio.webp";
import logoMin from "../../assets/img/logo-min.svg";

import * as Styled from "./styles";

interface CollapseNavStatesProps {
  gerenciar: boolean;
  financeiro: boolean;
  meusDados: boolean;
  financeiroComercio: boolean;
}

interface CollapseNavItemsListProps {
  name: string;
  stateName: "gerenciar" | "financeiro" | "meusDados" | "financeiroComercio";
  pathnames: { pathname: string; name: string }[];
  collapseItems: JSX.Element[];
  icon: JSX.Element;
  exclude?: string[];
}

const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();
  const { signOut, user_name, roles, fantasy_name } = useAuth();
  const {
    isSidebarMaximized,
    handleToggleSidebar,
    collapseNavStates,
    setCollapseNavStates,
  } = useSidebar();

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const handleSetNavMinVisibility = useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  const handleToggleCollapseNavButton = useCallback(
    (path: "gerenciar" | "financeiro" | "meusDados" | "financeiroComercio") => {
      if (!isSidebarMaximized) {
        handleToggleSidebar();
      }
      setCollapseNavStates((state) => {
        const newState = {};
        Object.keys(state).forEach((key) => {
          if (key === path) {
            Object.assign(newState, { [key]: !state[key] });
          } else {
            Object.assign(newState, { [key]: false });
          }
        });
        return newState as CollapseNavStatesProps;
      });
    },
    [isSidebarMaximized, handleToggleSidebar, setCollapseNavStates],
  );

  const lastItems = useMemo(() => {
    return [
      {
        pathname: "/dashboard",
        name: "Dashboard",
        icon: <FiHome size={18} />,
      },
    ];
  }, []);

  const nonCollapseNavLastItems = useMemo(
    () =>
      lastItems.map((nonPath: any, index) => {
        return (
          <Styled.NavItem
            isSidebarMaximized={isSidebarMaximized}
            isCurrentPage={pathname === nonPath.pathname}
            key={index + Math.random()}
          >
            <Styled.NavButton to={nonPath.pathname}>
              {nonPath.icon} <span>{nonPath.name}</span>
            </Styled.NavButton>
          </Styled.NavItem>
        );
      }),
    [isSidebarMaximized, lastItems, pathname],
  );

  const nonCollapsePathnames = useMemo(() => {
    const pathnames = [
      {
        pathname: "/pedidos",
        name: "Pedidos",
        icon: <FiFileText size={18} />,
        exclude: ["loocalFinancial"],
      },
      {
        pathname: "/novo-pedido",
        name: "Novo Pedido",
        icon: <FiFilePlus size={18} />,
        exclude: [
          "loocalFinancial",
          "loocalOperator",
          "loocalOperatorAdmin",
          "mainOfficeAdmin",
        ],
      },
    ];

    return pathnames;
  }, []);

  const nonCollapseNavItems = useMemo(
    () =>
      nonCollapsePathnames.map((nonPath, index) => {
        return (
          <Styled.NavItem
            isSidebarMaximized={isSidebarMaximized}
            isCurrentPage={pathname === nonPath.pathname}
            key={index + Math.random()}
          >
            <Styled.NavButton to={nonPath.pathname}>
              {nonPath.icon} <span>{nonPath.name}</span>
            </Styled.NavButton>
          </Styled.NavItem>
        );
      }),
    [nonCollapsePathnames, isSidebarMaximized, pathname],
  );

  const gerenciarCollapsePathnames = useMemo(() => {
    if (!roles || !Array.isArray(roles)) {
      return [];
    }

    if (roles.includes("superAdmin") || roles.includes("mainOfficeAdmin")) {
      const pathnames = [
        { pathname: "/gerenciar/operadores", name: "Operadores" },
        { pathname: "/gerenciar/estabelecimentos", name: "Estabelecimentos" },
        { pathname: "/gerenciar/entregadores", name: "Entregadores" },
        { pathname: "/gerenciar/por-cidade", name: "Por Cidade" },
      ];

      return pathnames;
    }

    if (
      roles.includes("loocalOperatorAdmin") ||
      roles.includes("loocalFinancial")
    ) {
      const pathnames = [
        { pathname: "/gerenciar/por-cidade", name: "Por Cidade" },
      ];

      return pathnames;
    }

    return [];
  }, [roles]);

  const financeiroCollapsePathnames = useMemo(() => {
    if (!roles || !Array.isArray(roles)) {
      return [];
    }

    if (roles.includes("superAdmin") || roles.includes("loocalFinancial")) {
      const pathnames = [
        { pathname: "/financeiro/entregadores", name: "Entregadores" },
        { pathname: "/financeiro/estabelecimentos", name: "Estabelecimentos" },
      ];

      return pathnames;
    }

    if (roles.includes("loocalOperatorAdmin")) {
      const pathnames = [
        { pathname: "/financeiro/estabelecimentos", name: "Estabelecimentos" },
      ];

      return pathnames;
    }
    return [];
  }, [roles]);

  const gerenciarCollapseItems = useMemo(
    () =>
      gerenciarCollapsePathnames.map((genPath, index) => (
        <Styled.CollapseItem
          isCurrentPage={pathname.includes(genPath.pathname)}
          key={index + Math.random()}
        >
          <Styled.CollapseItemButton to={genPath.pathname}>
            •&nbsp;&nbsp; {genPath.name}
          </Styled.CollapseItemButton>
        </Styled.CollapseItem>
      )),
    [gerenciarCollapsePathnames, pathname],
  );

  const financeiroCollapseItems = useMemo(
    () =>
      financeiroCollapsePathnames.map((finPath, index) => (
        <Styled.CollapseItem
          isCurrentPage={pathname.includes(finPath.pathname)}
          key={index + Math.random()}
        >
          <Styled.CollapseItemButton to={finPath.pathname}>
            •&nbsp;&nbsp; {finPath.name}
          </Styled.CollapseItemButton>
        </Styled.CollapseItem>
      )),
    [financeiroCollapsePathnames, pathname],
  );

  const collapseNavItemsList: CollapseNavItemsListProps[] = useMemo(() => {
    return [
      {
        name: "Gerenciar",
        stateName: "gerenciar",
        pathnames: gerenciarCollapsePathnames,
        collapseItems: gerenciarCollapseItems,
        icon: <RiUserSettingsLine size={19} />,
        include: ["loocalOperatorAdmin", "mainOfficeAdmin"],
      },
      {
        name: "Financeiro",
        stateName: "financeiro",
        pathnames: financeiroCollapsePathnames,
        collapseItems: financeiroCollapseItems,
        icon: <FiDollarSign size={18} />,
        include: ["loocalFinancial", "loocalOperatorAdmin", "mainOfficeAdmin"],
      },
    ];
  }, [
    gerenciarCollapseItems,
    financeiroCollapseItems,
    gerenciarCollapsePathnames,
    financeiroCollapsePathnames,
  ]);

  const collapseNavItems = useMemo(
    () =>
      collapseNavItemsList.map((colListItem, index) => {
        return (
          <Styled.CollapseNavItem
            isSidebarMaximized={isSidebarMaximized}
            isCurrentPage={colListItem.pathnames.some((colListItemPaths) =>
              pathname.includes(colListItemPaths.pathname),
            )}
            isCollapsed={collapseNavStates[colListItem.stateName]}
            key={index + Math.random()}
          >
            <Styled.CollapseNavButton
              onClick={() =>
                handleToggleCollapseNavButton(colListItem.stateName)
              }
            >
              {colListItem.icon}
              <Styled.NavText isSidebarMaximized={isSidebarMaximized}>
                <span>{colListItem.name}</span>
                <span>
                  {collapseNavStates[colListItem.stateName] ? "-" : "+"}
                </span>
              </Styled.NavText>
            </Styled.CollapseNavButton>
            <Styled.CollapseItems
              isCollapsed={collapseNavStates[colListItem.stateName]}
              pathLength={colListItem.pathnames.length}
            >
              {colListItem.collapseItems}
            </Styled.CollapseItems>
          </Styled.CollapseNavItem>
        );
      }),
    [
      isSidebarMaximized,
      pathname,
      collapseNavItemsList,
      collapseNavStates,
      handleToggleCollapseNavButton,
    ],
  );

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = (event: MouseEvent) => {
        if (!navRef.current?.contains(event.target as HTMLElement)) {
          setIsOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
    return undefined;
  }, [isOpen]);

  return (
    <Styled.Container isSidebarMaximized={isSidebarMaximized} ref={navRef}>
      <title>Backoffice - Loocal</title>

      <Styled.Header>
        <Styled.Burger isOpen={isOpen} onClick={handleSetNavMinVisibility}>
          <div />
          <div />
          <div />
        </Styled.Burger>
        <Styled.ArrowContainer onClick={handleToggleSidebar}>
          {isSidebarMaximized ? (
            <FiMinimize2 size={15} />
          ) : (
            <FiMaximize2 size={15} />
          )}
        </Styled.ArrowContainer>
        <Styled.ImageContainer isSidebarMaximized={isSidebarMaximized}>
          <img src={logoImg} alt="Loocal Logotipo" />
        </Styled.ImageContainer>

        <Styled.GreetingContainer>
          {isSidebarMaximized ? (
            <div>
              <p>Bem vindo,</p>
              <p>
                {user_name} {fantasy_name}!
              </p>
            </div>
          ) : (
            <Styled.LogoMin src={logoMin} alt="Loocal Logotipo" />
          )}
        </Styled.GreetingContainer>
        <Styled.NavSide isOpen={isOpen}>
          <ul>
            {nonCollapseNavItems}
            {collapseNavItems}
            {nonCollapseNavLastItems}
          </ul>
        </Styled.NavSide>
      </Styled.Header>
      <Styled.Footer>
        <Styled.LogoutContainer
          onClick={handleSignOut}
          isSidebarMaximized={isSidebarMaximized}
        >
          <AiOutlinePoweroff size={18} /> <span>Sair</span>
        </Styled.LogoutContainer>
        <Styled.Copyright isSidebarMaximized={isSidebarMaximized}>
          © 2025 Loocal
        </Styled.Copyright>
      </Styled.Footer>
    </Styled.Container>
  );
};

export default Sidebar;
