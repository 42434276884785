/* eslint-disable */
import React from "react";
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from "react-router-dom";

import { useAuth } from "../hooks/AuthContext";

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isAdmin?: boolean;
  isOperator?: boolean;
  isFinancial?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isAdmin = false,
  isFinancial = false,
  isOperator = false,
  component: Component,
  ...rest
}) => {
  const { user_name } = useAuth();
  return (
    <>
      {isAdmin ? (
        <ReactDOMRoute
          {...rest}
          render={({ location }) => {
            if (user_name) {
              return <Component />;
            }
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location },
                }}
              />
            );
          }}
        />
      ) : (
        <ReactDOMRoute
          {...rest}
          render={({ location }) => {
            return isPrivate === !!user_name ? (
              <Component />
            ) : (
              <Redirect
                to={{
                  pathname: isPrivate ? "/" : "/start",
                  state: { from: location },
                }}
              />
            );
          }}
        />
      )}
    </>
  );
};

export default Route;
