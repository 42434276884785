/* eslint-disable radix */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  FiRefreshCw,
  FiZoomIn,
  FiX,
  FiPause,
  FiPlay,
  FiBriefcase,
  FiCompass,
  FiSmartphone,
  FiTruck,
  FiSearch,
  FiRotateCcw,
} from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";
import { FormHandles } from "@unform/core";
import Swal from "sweetalert2";
import "react-circular-progressbar/dist/styles.css";
import api from "../../services/api";

import { useSidebar } from "../../hooks/SidebarContext";
import useStateCallback from "../../hooks/UseStateCallback";
import { useAuth } from "../../hooks/AuthContext";

import Sidebar from "../../components/Sidebar";
import Skeleton from "../../components/Skeleton";
import DetailsModal from "../../components/DetailsModal";
import Pagination from "../../components/Pagination";
import LoadingSpinner from "../../components/LoadingSpinner";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";

import { phoneFormat } from "../../utils/inputAndTextMasks";

import { Order } from "../../@types/customTypes";
import {
  CANCELED_STATUS_ID,
  DELIVERED_STATUS_ID,
  WAITING_STATUS_ID,
} from "./constants";
import * as Styled from "./styles";
import LoocalAutocompleteInput from "../../components/LoocalAutocompleteInput";
import SelectButton from "../../components/SelectButton";
import Input from "../../components/Input";
import { statusNameType } from "../../utils/statusNameType";
import { getOrderColor } from "../../utils/getColores";
import apiV3 from "../../services/apiv3";
import { formatDateToSaoPaulo } from "../../utils/formatLocale";
import { getUfByStateId } from "../../utils/statesTypes";
import SelectDeliverymanModal from "../../components/SelectDeliverymanModal";

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([] as Order[]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ordersCountdown, setOrdersCountdown] = useState(120);
  const [currentOrder, setCurrentOrder] = useStateCallback<null | Order>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentSize, setCurrentSize] = useState<number | null>(null);
  const [count1015Min, setCount1015Min] = useState<string | "">("0");
  const [count1520Min, setCount1520Min] = useState<string | "">("0");
  const [count20PlusMin, setCount20PlusMin] = useState<string | "">("0");
  const [debounceTimer, setDebounceTimer] = useState<null | NodeJS.Timeout>(
    null,
  );
  const [idOrder, setIdOrder] = useState<any>();
  const [query, setQuery] = useState("");
  const [isPaused, setIsPaused] = useState(false);
  const [orderStatus, setOrderStatus] = useState("todas");
  const formRef = useRef<FormHandles>(null);
  const getOrdersInterval = useRef<NodeJS.Timeout | null>(null);
  const ordersCountdownInterval = useRef<NodeJS.Timeout | null>(null);
  const [orderCounts, setOrdersCounts] = useState<any>({});
  const [isSelectDeliverymanModalOpen, setIsSelectDeliverymanModalOpen] =
    useState(false);
  const [orderToUpdate, setOrderToUpdate] = useState<Order | null>(null);
  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const { cities } = useAuth();

  const [buttonSelected, setButtonSelected] = useState<string | null>(null);

  const allOrders = orders.flatMap((dispatch) => {
    // Inclui o entregador em cada pedido associado ao dispatch
    const deliveryman = dispatch.deliveryman || null;
    return (dispatch.orders || []).map((order: Order) => ({
      ...order,
      delivery_id: dispatch.id,
      company_id: dispatch.company_id,
      dispatch_createdAt: dispatch.created_at,
      deliveryman,
      color: getOrderColor(statusNameType(order.order_status_id)),
    }));
  });

  interface FormData {
    city_name: string;
  }

  const handleClearFilters = () => {
    setButtonSelected(null);
    setIdOrder(null);
    formRef.current?.reset();
  };

  const countOrders = useCallback(async () => {
    try {
      const result = await api.get("/backoffice-orders-count");
      setOrdersCounts(result.data);
    } catch (error) {
      addToast("Erro ao buscar lista!", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  }, [addToast, orderStatus]);

  const getCityPlaceholder = () => {
    if (cities && cities.length > 0) {
      return `Cidade(s) - ${cities.map((city) => city.name).join(", ")}`;
    }
    return "Cidade";
  };

  const handleGetOrders = useCallback(
    async (
      size = 8,
      page = 1,
      cityOrder = null,
      deliveryman = null,
      startTime = null,
      endTime = null,
      order = null,
      company = null,
      order_status_id = null,
    ) => {
      try {
        setIsLoading(true);

        if (cities && cities.length > 0) {
          cityOrder = cities.map((city) => city.id).join(",");
        }

        const url = "deliveries";

        const { data: ordersResponse } = await apiV3.get<any>(url, {
          params: {
            "page[size]": size,
            "page[number]": page,
            city_id: cityOrder,
            deliveryman_id: deliveryman,
            "timeRanges[0][start_time]": startTime,
            "timeRanges[0][end_time]": endTime,
            order_id: order,
            order_status_id,
            company_id: company,
          },
        });

        setCurrentPage(page);
        setTotalPages(ordersResponse.meta.last_page);
        setOrders(ordersResponse.data);
        setOrderToUpdate(null);
        setIsSelectDeliverymanModalOpen(false);
        setIsLoading(false);
      } catch (error) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast],
  );

  const getSearchParams = useCallback(() => {
    const formData: FormData | any = formRef.current?.getData();
    let cityOrder = null;
    let deliveryman = null;
    let company = null;
    let startTime = null;
    let endTime = null;
    let order_status_id = null;

    if (orderStatus === "aguardando") {
      order_status_id = 1;
    } else if (orderStatus === "a-caminho-da-retirada") {
      order_status_id = 7;
    } else if (orderStatus === "todas") {
      order_status_id = null;
    }

    if (formData !== undefined) {
      cityOrder = formData.city_name.split("-")[0].trim();
      deliveryman = formData.deliveryman_name.split("-")[0].trim();
      company = formData.company_name.split("-")[0].trim();
    }

    if (buttonSelected) {
      // eslint-disable-next-line prefer-destructuring
      startTime = buttonSelected.split("-")[0];

      if (buttonSelected !== "20-20") {
        // eslint-disable-next-line prefer-destructuring
        endTime = buttonSelected.split("-")[1];
      }
    }

    return {
      cityOrder,
      deliveryman,
      startTime,
      endTime,
      idOrder,
      company,
      order_status_id,
    };
  }, [buttonSelected, idOrder, orderStatus]);

  const debounceSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      e.persist();
      const newDebounceTimer = setTimeout(() => {
        const {
          cityOrder,
          deliveryman,
          startTime,
          endTime,
          company,
          order_status_id,
        } = getSearchParams();

        countOrders();

        handleGetOrders(
          currentSize || 8,
          currentPage,
          cityOrder,
          deliveryman,
          startTime,
          endTime,
          idOrder,
          company,
          order_status_id,
        );
        setQuery(e.target.value);
      }, 500);
      setDebounceTimer(newDebounceTimer);
    },
    [
      debounceTimer,
      handleGetOrders,
      currentSize,
      idOrder,
      countOrders,
      getSearchParams,
      currentPage,
    ],
  );

  const handleSetIsPaused = useCallback(() => {
    setIsPaused((state) => !state);

    if (isPaused) {
      const { cityOrder, deliveryman, startTime, endTime, company } =
        getSearchParams();

      countOrders();

      handleGetOrders(
        currentSize || 8,
        currentPage,
        cityOrder,
        deliveryman,
        startTime,
        endTime,
        idOrder,
        company,
      );

      getOrdersInterval.current = setInterval(() => {
        countOrders();

        handleGetOrders(
          currentSize || 8,
          currentPage,
          cityOrder,
          deliveryman,
          startTime,
          endTime,
          idOrder,
          company,
        );
        addToast("Listagem atualizada!", {
          appearance: "info",
          autoDismiss: true,
        });
      }, 120 * 1000);

      ordersCountdownInterval.current = setInterval(() => {
        setOrdersCountdown((state) => (state !== 0 ? state - 1 : 120));
      }, 1000);
    } else {
      setOrdersCountdown(120);
      clearInterval(getOrdersInterval.current as NodeJS.Timeout);
      clearInterval(ordersCountdownInterval.current as NodeJS.Timeout);
    }
  }, [
    isPaused,
    addToast,
    handleGetOrders,
    countOrders,
    currentPage,
    currentSize,
    getSearchParams,
    idOrder,
  ]);

  const handleOpenModal = useCallback(
    (orderId: number) => {
      const selectedOrder = allOrders?.find((order) => order.id === orderId);
      setCurrentOrder(selectedOrder as Order, () => setIsModalOpen(true));
    },
    [allOrders, setCurrentOrder],
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setCurrentOrder(null);
  }, [setIsModalOpen, setCurrentOrder]);

  const handleUpdateOrderStatus = useCallback(
    async (newStatus: number, orderId: number, deliverymanId?: number) => {
      try {
        setShowSpinner(true);
        if (newStatus === 1) {
          await apiV3.put(`orders/${orderId}/status`, {
            order_status_id: newStatus,
            deliveryman_id: deliverymanId,
          });
        } else {
          await api.put(`orders/${orderId}/status`, {
            order_status_id: newStatus,
            deliveryman_id: deliverymanId,
          });
        }

        const {
          cityOrder,
          deliveryman,
          startTime,
          endTime,
          company,
          order_status_id,
        } = getSearchParams();

        countOrders();

        handleGetOrders(
          currentSize || 8,
          currentPage,
          cityOrder,
          deliveryman,
          startTime,
          endTime,
          idOrder,
          company,
          order_status_id,
        );
        addToast("Status alterado com sucesso!", {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (e) {
        addToast("Ocorreu um erro ao atualizar status do pedido!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
    },
    [
      currentPage,
      handleGetOrders,
      addToast,
      currentSize,
      countOrders,
      getSearchParams,
      idOrder,
    ],
  );

  const handleSelect = useCallback(
    async (selected: string) => {
      if (buttonSelected === selected) {
        setButtonSelected(null);
      } else {
        setButtonSelected(selected);
      }
    },
    [buttonSelected],
  );

  const handleRefreshPage = useCallback(() => {
    const {
      cityOrder,
      deliveryman,
      startTime,
      endTime,
      company,
      order_status_id,
    } = getSearchParams();

    countOrders();

    handleGetOrders(
      currentSize || 8,
      currentPage,
      cityOrder,
      deliveryman,
      startTime,
      endTime,
      idOrder,
      company,
      order_status_id,
    );
  }, [
    countOrders,
    currentSize,
    currentPage,
    getSearchParams,
    handleGetOrders,
    idOrder,
  ]);

  const addNewOrder = useCallback(
    async (data) => {
      try {
        const newOrders = [];
        setShowSpinner(true);
        const dataToAddNewOrder = {
          customer_id: data.customer.id,
          customer: {
            name: data.customer.name,
            phones: data.customer.phones[0] || "",
            address: {
              address: data.customer.address.address,
              number: data.customer.address.number,
              complement: data.customer.address.complement,
              neighborhood: data.customer.address.neighborhood || "",
              latitude: data.customer.address.latitude,
              longitude: data.customer.address.longitude,
              city: data.customer.address.city.name,
              city_id: data.customer.address.city.id,
              uf: getUfByStateId(data.customer.address.city.state_id),
              postal_code: data.customer.address.postal_code || "",
            },
          },
          observation: data.observations[0],
          return: data.return,
          packet_type_id: data.packet_type_id,
          payment_type_id: data.payment_type_id,
          amount: data.amount,
          change: String(data.change),
          thermal_box: data.thermal_box,
          order_status_id: 1,
          get_sign: data.get_sign,
        };
        newOrders.push(dataToAddNewOrder);
        await apiV3.post("deliveries", {
          company_id: data.company_id,
          orders: newOrders,
          vehicles: [1, 2, 3],
        });
        handleRefreshPage();
        addToast("Pedido relançado com sucesso!", {
          appearance: "success",
          autoDismiss: true,
        });
        setShowSpinner(false);
      } catch (e) {
        const errorMessage =
          (e as any).response?.data?.error ||
          (e as any).response?.data?.message ||
          "Erro desconhecido.";

        addToast(
          `Ocorreu um erro ao atualizar status do pedido!, ${errorMessage}`,
          {
            appearance: "warning",
            autoDismiss: true,
          },
        );
        setShowSpinner(false);
      }
    },
    [addToast, handleRefreshPage],
  );

  const handleRelauchOrder = useCallback(
    async ({ order }) => {
      const { isConfirmed } = await Swal.fire({
        title: "Tem certeza?",
        text: `Tem certeza que deseja relançar o pedido ${order.id}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "var(--color-primary)",
        cancelButtonColor: "var(--color-danger)",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (isConfirmed) {
        addNewOrder(order);
      }
    },
    [addNewOrder],
  );

  const handleConfirmUpdateStatus = useCallback(
    async ({ type, order, statusId }) => {
      let textMessage = "";

      if (type === "cancel") {
        textMessage = `Tem certeza que deseja cancelar o pedido ${order.id}?`;
      } else if (type === "waiting") {
        setOrderToUpdate(order);
        setIsSelectDeliverymanModalOpen(true);
        return;
      } else if (type === "delivered") {
        if (
          order.order_status_id === CANCELED_STATUS_ID ||
          order.order_status_id === WAITING_STATUS_ID
        ) {
          return;
        }
        textMessage = `Tem certeza que deseja marcar o pedido ${order.id} com o status entregue?`;
      }

      const { isConfirmed } = await Swal.fire({
        title: "Tem certeza?",
        text: textMessage,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "var(--color-primary)",
        cancelButtonColor: "var(--color-danger)",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (isConfirmed) {
        handleUpdateOrderStatus(statusId, order.id);
      }
    },
    [handleUpdateOrderStatus],
  );

  const handleSelectDeliverymanConfirm = useCallback(() => {
    if (orderToUpdate) {
      handleUpdateOrderStatus(WAITING_STATUS_ID, orderToUpdate.id);
      setIsSelectDeliverymanModalOpen(false);
      setOrderToUpdate(null);
    }
  }, [handleUpdateOrderStatus, orderToUpdate]);

  useEffect(() => {
    const {
      cityOrder,
      deliveryman,
      startTime,
      endTime,
      company,
      order_status_id,
    } = getSearchParams();

    countOrders();

    handleGetOrders(
      currentSize || 8,
      currentPage,
      cityOrder,
      deliveryman,
      startTime,
      endTime,
      idOrder,
      company,
      order_status_id,
    );

    getOrdersInterval.current = setInterval(() => {
      handleGetOrders();
      addToast("Listagem atualizada!", {
        appearance: "info",
        autoDismiss: true,
      });
    }, 120 * 1000);

    ordersCountdownInterval.current = setInterval(() => {
      setOrdersCountdown((state) => (state !== 0 ? state - 1 : 120));
    }, 1000);

    return () => {
      if (getOrdersInterval.current) {
        clearInterval(getOrdersInterval.current);
      }
      if (ordersCountdownInterval.current) {
        clearInterval(ordersCountdownInterval.current);
      }
    };
  }, [currentPage, currentSize]);

  useEffect(() => {
    if (orderStatus === "aguardando") {
      setCount1015Min(orderCounts.count10_15min);
      setCount1520Min(orderCounts.count15_20min);
      setCount20PlusMin(orderCounts.count20plusmin);
    } else if (orderStatus === "a-caminho-da-retirada") {
      setCount1015Min(orderCounts.count10_15min_aguardando_retirada);
      setCount1520Min(orderCounts.count15_20min_aguardando_retirada);
      setCount20PlusMin(orderCounts.count20plusmin_aguardando_retirada);
    }
    handleClearFilters();
  }, [orderStatus]);

  return (
    <Container>
      <Sidebar />
      {showSpinner && <LoadingSpinner />}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        {isModalOpen && (
          <DetailsModal
            currentOrder={currentOrder as Order}
            setCurrentOrder={setCurrentOrder}
            handleCloseModal={handleCloseModal}
            isModalOpen={isModalOpen}
          />
        )}
        <Styled.OrdersContainer>
          <Styled.CardTitle isAdmin>
            <div>
              Listagem dos Meus Pedidos{" "}
              <FiRefreshCw size={20} onClick={() => handleRefreshPage()} />
              {isPaused ? (
                <FiPlay size={20} onClick={handleSetIsPaused} />
              ) : (
                <FiPause size={20} onClick={handleSetIsPaused} />
              )}
            </div>
            <Styled.CircularProgress
              value={ordersCountdown}
              isPaused={isPaused}
              strokeWidth={12}
              minValue={0}
              maxValue={125}
              isModalOpen={isModalOpen}
            />
            <div>
              {
                <Styled.SearchForm ref={formRef} onSubmit={() => {}}>
                  <Styled.GridForm>
                    <Styled.ContainerDiv>
                      <LoocalAutocompleteInput
                        type="normal"
                        id="city_name"
                        name="city_name"
                        placeholder={getCityPlaceholder()}
                        formRef={formRef}
                        disabled={cities && cities.length > 0}
                        endpoint="city"
                        // onSelect={(city: any) => setSelectedCity(city)}
                        icon={FiCompass}
                      />
                    </Styled.ContainerDiv>

                    <Styled.ContainerDiv>
                      <LoocalAutocompleteInput
                        type="normal"
                        id="deliveryman_name"
                        name="deliveryman_name"
                        placeholder="Entregador"
                        formRef={formRef}
                        endpoint="deliveryman"
                        icon={FiTruck}
                      />
                    </Styled.ContainerDiv>
                    <Styled.ContainerDiv>
                      <Input
                        type="text"
                        id="order_id"
                        name="order_id"
                        placeholder="ID do Pedido"
                        onChange={(order: any) =>
                          setIdOrder(order.target.value)
                        }
                        icon={FiSmartphone}
                        maxLength={15}
                      />
                    </Styled.ContainerDiv>

                    <Styled.ContainerDiv>
                      <LoocalAutocompleteInput
                        type="normal"
                        id="company_name"
                        name="company_name"
                        placeholder="Comércio"
                        formRef={formRef}
                        endpoint="company"
                        icon={FiBriefcase}
                      />
                    </Styled.ContainerDiv>
                  </Styled.GridForm>

                  <div style={{ width: "100%", marginTop: "10px" }}>
                    <div
                      style={{
                        maxWidth: "100%",
                        border: "1px solid rgba(220,220,220)",
                        padding: "16px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "4px",
                      }}
                    >
                      <label
                        htmlFor="order-status-select"
                        style={{ minWidth: "140px" }}
                      >
                        Regua de tempo:
                      </label>
                      <select
                        id="order-status-select"
                        value={orderStatus}
                        onChange={(e) => {
                          setOrderStatus(e.target.value);
                          setButtonSelected(null);
                        }}
                        style={{
                          width: "100%",
                          padding: "10px",
                          fontSize: "16px",
                          border: "1px solid rgba(220,220,220)",
                          borderRadius: "4px",
                          backgroundColor: "#fff",
                          color: "#333",
                        }}
                      >
                        <option value="todas">Todas</option>
                        <option value="aguardando">Aguardando</option>
                        <option value="a-caminho-da-retirada">
                          A caminho da retirada
                        </option>
                      </select>
                    </div>

                    {orderStatus !== "todas" && (
                      <Styled.GridFormButtons>
                        <Styled.ContainerDiv>
                          <SelectButton
                            onSelect={() => handleSelect("10-15")}
                            backgroundColor={
                              buttonSelected === "10-15"
                                ? "#00a759"
                                : "#aeaeae6e"
                            }
                            hoverColor="#00a759"
                            label={count1015Min}
                            labelHeader="10 min - 15 min"
                            isSelected={buttonSelected === "10-15"}
                          />
                        </Styled.ContainerDiv>

                        <Styled.ContainerDiv>
                          <SelectButton
                            onSelect={() => handleSelect("15-20")}
                            backgroundColor={
                              buttonSelected === "15-20"
                                ? "#fd7e14"
                                : "#aeaeae6e"
                            }
                            hoverColor="#fd7e14"
                            label={count1520Min}
                            labelHeader="15 min - 20 min"
                            isSelected={buttonSelected === "15-20"}
                          />
                        </Styled.ContainerDiv>

                        <Styled.ContainerDiv>
                          <SelectButton
                            onSelect={() => handleSelect("20-20")}
                            backgroundColor={
                              buttonSelected === "20-20"
                                ? "#c31111"
                                : "#aeaeae6e"
                            }
                            hoverColor="#c53030"
                            label={count20PlusMin}
                            labelHeader="+20 min"
                            isSelected={buttonSelected === "20-20"}
                          />
                        </Styled.ContainerDiv>
                      </Styled.GridFormButtons>
                    )}
                  </div>

                  <Styled.ContainerDivButtonActions>
                    <Styled.SearchButtonForm
                      type="button"
                      onClick={handleClearFilters}
                    >
                      Limpar Filtros <FiX />
                    </Styled.SearchButtonForm>

                    <Styled.SearchButtonForm
                      type="button"
                      onClick={debounceSearch}
                    >
                      Buscar
                      <FiSearch />
                    </Styled.SearchButtonForm>
                  </Styled.ContainerDivButtonActions>

                  <Styled.ContainerDivButton />
                </Styled.SearchForm>
              }
            </div>
          </Styled.CardTitle>
          {isLoading ? (
            <Styled.OrdersShimmer isAdmin>
              {[...Array(currentSize || 8)].map((_, index) => (
                <Skeleton key={index} />
              ))}
            </Styled.OrdersShimmer>
          ) : (
            <Styled.Ul>
              {/* @ts-ignore */}
              <ReactTooltip />
              {allOrders.map((order) => {
                return (
                  <div key={order.id} style={{ position: "relative" }}>
                    {statusNameType(order.order_status_id) === "Cancelado" && (
                      <div
                        style={{
                          position: "absolute",
                          top: window.innerWidth <= 768 ? "10%" : "5%",
                          right: window.innerWidth <= 768 ? "5%" : "2%",
                        }}
                      >
                        <Styled.ActionField>
                          <FiRotateCcw
                            size={27}
                            onClick={() =>
                              handleRelauchOrder({
                                order,
                              })
                            }
                          />
                        </Styled.ActionField>
                      </div>
                    )}
                    {order.ifood_order && (
                      <div
                        style={{
                          position: "absolute",
                          top: window.innerWidth <= 768 ? "2%" : "5%",
                          right: window.innerWidth <= 768 ? "2%" : "5%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: 4,
                        }}
                      >
                        <img
                          src="https://cliente-dev.loocalapp.com/dashboard/ifood.png"
                          alt="ifood"
                        />
                        <span style={{ fontWeight: "500" }}>
                          # {order.ifood_order.display_id}
                        </span>
                      </div>
                    )}
                    <Styled.Li
                      color={order.color || "lightblue"}
                      style={{ marginBottom: "25px" }}
                      key={order.id}
                      isAdmin
                    >
                      <Styled.CommonField
                        data-tip={`${order.company.fantasy_name}`}
                      >
                        <span>Estabelecimento:</span>
                        <strong>{order.company.fantasy_name}</strong>
                      </Styled.CommonField>

                      <Styled.CommonField>
                        <span>ID:</span>
                        <strong>
                          {order.id} (
                          {order.delivery_id > 0 ? order.delivery_id : "-"})
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Data de criação:</span>
                        <strong>
                          {formatDateToSaoPaulo(order.created_at)}
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Data do Delivery:</span>
                        <strong>
                          {order
                            ? formatDateToSaoPaulo(order.dispatch_createdAt)
                            : "Não informado"}
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField
                        data-tip={order.company.address.city.name ?? null}
                      >
                        <span>Cidade:</span>
                        <strong>{order.company.address.city.name}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField data-tip={`${order.customer.name}`}>
                        <span>Cliente:</span>
                        <strong>{order.customer.name}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField
                        data-tip={
                          order.deliveryman
                            ? `${order.deliveryman.name} (${order.deliveryman.nickname})`
                            : null
                        }
                      >
                        <span>Entregador:</span>
                        <strong>
                          {order.deliveryman ? order.deliveryman.name : "N/A"}
                        </strong>
                      </Styled.CommonField>

                      <Styled.CommonField>
                        <span>
                          Telefone{" "}
                          <span role="img" aria-label="Moto">
                            🛵
                          </span>
                          :
                        </span>
                        <strong>
                          {order.deliveryman
                            ? phoneFormat(
                                order.deliveryman?.phones[0] as string,
                              )
                            : "-"}
                        </strong>
                      </Styled.CommonField>

                      <Styled.CommonField>
                        <span>Origem:</span>
                        <strong>{order.company.address.neighborhood}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Destino:</span>
                        <strong>{order.customer.address.neighborhood}</strong>
                      </Styled.CommonField>

                      <div>
                        <Styled.OrderStatus
                          aria-label="status"
                          color={order.color || "lightblue"}
                        >
                          <section aria-label="status-buttons">
                            <button
                              type="button"
                              onClick={() =>
                                handleConfirmUpdateStatus({
                                  type: "cancel",
                                  order,
                                  statusId: CANCELED_STATUS_ID,
                                })
                              }
                            >
                              Marcar como cancelado
                            </button>
                            {order.order_status_id !== 6 &&
                              order.order_status_id !== 5 && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleConfirmUpdateStatus({
                                      type: "waiting",
                                      order,
                                      statusId: WAITING_STATUS_ID,
                                    })
                                  }
                                >
                                  {order.order_status_id === 1
                                    ? "Atrelar entregador"
                                    : "Realocar entregador"}
                                </button>
                              )}
                            <button
                              type="button"
                              onClick={() =>
                                handleConfirmUpdateStatus({
                                  type: "delivered",
                                  order,
                                  statusId: DELIVERED_STATUS_ID,
                                })
                              }
                            >
                              Marcar como Entregue
                            </button>
                          </section>
                        </Styled.OrderStatus>
                      </div>

                      <div style={{ display: "flex", gap: 10 }}>
                        <Styled.StatusField color={order.color || "lightblue"}>
                          {statusNameType(order.order_status_id) === "Pronto"
                            ? "Chegou na coleta"
                            : statusNameType(order.order_status_id)}
                        </Styled.StatusField>
                        <Styled.ActionField>
                          <FiZoomIn
                            size={27}
                            onClick={() => handleOpenModal(order.id)}
                          />
                        </Styled.ActionField>
                      </div>
                    </Styled.Li>
                  </div>
                );
              })}
            </Styled.Ul>
          )}
          {isModalOpen && (
            <DetailsModal
              currentOrder={currentOrder as Order}
              setCurrentOrder={setCurrentOrder}
              handleCloseModal={handleCloseModal}
              isModalOpen={isModalOpen}
            />
          )}
          {orderToUpdate && (
            <SelectDeliverymanModal
              isOpen={isSelectDeliverymanModalOpen}
              onRequestClose={() => {
                setOrderToUpdate(null);
                setIsSelectDeliverymanModalOpen(false);
              }}
              handleGetOrders={handleGetOrders}
              onConfirm={handleSelectDeliverymanConfirm}
              order={orderToUpdate}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          )}
          {currentPage !== 0 && (
            <Pagination
              updateList={handleGetOrders}
              totalPages={totalPages}
              currentPage={currentPage}
              searchQuery={query}
              sizes={[8, 10, 20, 30]}
              currentSize={currentSize}
              setCurrentSize={setCurrentSize}
            />
          )}
        </Styled.OrdersContainer>
      </ContainerMain>
    </Container>
  );
};

export default Orders;
