import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import { useParams, useHistory } from "react-router-dom";
import {
  FiArrowLeft,
  FiAtSign,
  FiLock,
  FiNavigation,
  FiSettings,
  FiUnlock,
  FiUser,
} from "react-icons/fi";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";
import { useSidebar } from "../../hooks/SidebarContext";
import Sidebar from "../../components/Sidebar";
import Input from "../../components/Input";
import Button from "../../components/Button";
import LoadingSpinner from "../../components/LoadingSpinner";
import Select from "../../components/ReactSelect";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";
import * as Styled from "./styles";
import apiV3 from "../../services/apiv3";
import { OperatorsFromGetOperators } from "../Operators";
import api from "../../services/api";

interface OperatorFormData {
  email: string;
  name: string;
  roles: { label: string; value: string }[];
  password: string;
  password_confirmation: string;
  states?: { label: string; value: string }[];
  cities?: { label: string; value: string }[];
}

const OperatorsById: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [roles, setRoles] = useState<{ label: string; value: string }[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<
    { label: string; value: string }[]
  >([]);
  const [states, setStates] = useState<{ label: string; value: string }[]>([]);
  const [selectedStates, setSelectedStates] = useState<
    { label: string; value: string }[]
  >([]);
  const [cities, setCities] = useState<{ label: string; value: string }[]>([]);
  const [selectedCities, setSelectedCities] = useState<
    { label: string; value: string }[]
  >([]);
  const [childrenStateUpdater] = useState(0);

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const formRef = useRef<FormHandles>(null);

  interface CustomStyles {
    control: (provided: any) => any;
    multiValue: (provided: any) => any;
    multiValueLabel: (provided: any) => any;
    multiValueRemove: (provided: any) => any;
  }

  const customStyles: CustomStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "40px",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#e4e4e4",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#333",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#333",
      ":hover": {
        backgroundColor: "#ccc",
        color: "#000",
      },
    }),
  };

  const submitOperatorForm = useCallback(
    async (data: OperatorFormData) => {
      setShowSpinner(true);
      setIsLoading(true);
      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email("Email inválido")
            .required("Email obrigatório"),
          name: Yup.string().required("Nome obrigatório"),
          roles: Yup.array()
            .of(
              Yup.object().shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              }),
            )
            .test(
              "is-not-empty",
              "Pelo menos um cargo deve ser selecionado",
              () => selectedRoles && selectedRoles.length > 0,
            ),
          password: Yup.string()
            .nullable()
            .test(
              "min-length-if-not-null",
              "A senha deve ter pelo menos 4 caracteres",
              (value) => !value || value.length >= 4,
            ),
          password_confirmation: Yup.string()
            .notRequired()
            .oneOf([Yup.ref("password")], "Confirmação inválida")
            .nullable(),
        });
        await schema.validate(data);
        const dataToSubmitOperator: any = {
          user: {
            name: data.name,
            email: data.email,
          },
          roles: selectedRoles.map((role) => role.value),
          state_ids: (selectedStates || []).map((state) => state.value),
          cities: (selectedCities || []).map((state) => state.value),
        };

        if (data.password) {
          dataToSubmitOperator.user.password = data.password;
        }

        await apiV3.put(`operators/${id}`, dataToSubmitOperator);

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Alterações realizadas com sucesso!",
        });
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: `Não foi possível cadastrar o operador.<br />Motivo: ${
            error?.message ?? error?.response?.data ?? "Erro desconhecido"
          }`,
        });
      }
      setShowSpinner(false);
      setIsLoading(false);
    },
    [selectedRoles, selectedStates, selectedCities, id],
  );

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const { data: rolesData } = await apiV3.get("/auth/roles");
        const mappedRoles = rolesData.map((role: { name: string }) => ({
          label: role.name,
          value: role.name,
        }));
        setRoles(mappedRoles);
      } catch (error) {
        addToast("Ocorreu um erro ao carregar os cargos!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    };

    fetchRoles();
  }, [addToast]);

  const fetchStates = useCallback(async () => {
    setShowSpinner(true);
    setStates([]);
    formRef.current?.setData({
      states: [],
    });
    try {
      const { data: statesData } = await api.get("/states");
      const mappedStates = statesData.map(
        (state: { id: number; name: string }) => ({
          label: state.name,
          value: state.id.toString(),
        }),
      );
      setStates((prevStates) => [...prevStates, ...mappedStates]);
    } catch (error) {
      addToast("Ocorreu um erro ao carregar os estados!", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  }, [addToast]);

  const getCities = useCallback(
    async (state_id: number) => {
      setShowSpinner(true);
      setCities([]);
      formRef.current?.setData({
        cities: [],
      });
      try {
        const { data: citiesData } = await api.get(`/cities/${state_id}`);
        const mappedCities = citiesData.map(
          (city: { id: number; name: string }) => ({
            label: city.name,
            value: city.id.toString(),
          }),
        );
        setCities((prevCities) => [...prevCities, ...mappedCities]);
      } catch (error) {
        addToast("Ocorreu um erro ao carregar as cidades!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
    },
    [addToast],
  );

  useEffect(() => {
    const fetchOperator = async () => {
      setShowSpinner(true);
      try {
        const { data } = await apiV3.get<OperatorsFromGetOperators>(
          `/operators/${id}`,
        );
        const stateIds = data.cities.map((city) => city.state_id);
        const uniqueStateIds = Array.from(new Set(stateIds));

        const stateResponses = await api.get("/states");
        const foundStates = stateResponses.data
          .filter((state: { id: number }) => uniqueStateIds.includes(state.id))
          .map((state: { id: number; name: string }) => ({
            label: state.name,
            value: state.id.toString(),
          }));

        formRef.current?.setData({
          email: data.email,
          name: data.name,
          roles: data.roles.map((role) => ({
            label: role,
            value: role,
          })),
          states: foundStates,
          cities: data.cities.map((city) => ({
            label: city.name,
            value: city.id.toString(),
          })),
        });

        setSelectedRoles(
          data.roles.map((role) => ({
            label: role,
            value: role,
          })),
        );

        setSelectedCities(
          data.cities.map((city) => ({
            label: city.name,
            value: city.id.toString(),
          })),
        );

        setSelectedStates(foundStates);

        // Fetch cities for each state
        await Promise.all(uniqueStateIds.map((stateId) => getCities(stateId)));
        await fetchStates();
      } catch (error) {
        addToast("Ocorreu um erro ao carregar os dados do operador!", {
          appearance: "warning",
          autoDismiss: true,
        });
        history.push("/gerenciar/operadores");
      }
      setShowSpinner(false);
    };

    fetchOperator();
  }, [id, addToast, history, getCities, fetchStates]);

  return (
    <Container>
      <Sidebar />
      {showSpinner && <LoadingSpinner />}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.OperatorContainer>
          <Styled.Link to="/gerenciar/operadores">
            <FiArrowLeft size={20} />
            Voltar
          </Styled.Link>
          <Styled.Form
            onSubmit={submitOperatorForm}
            ref={formRef}
            autoComplete="off"
          >
            <Styled.Strong>Dados do responsável</Styled.Strong>
            <Styled.Row>
              <Select
                id="roles"
                name="roles"
                placeholder="Cargo"
                formRef={formRef}
                isMulti
                options={roles}
                styles={customStyles}
                value={selectedRoles}
                onChange={(
                  selectedOptions: { label: string; value: string }[],
                ) => {
                  setSelectedRoles(selectedOptions);
                  formRef.current?.setData({
                    roles: selectedOptions,
                  });
                }}
                icon={FiSettings}
              />

              <Input
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                icon={FiAtSign}
              />
            </Styled.Row>

            <Styled.Row>
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Nome"
                icon={FiUser}
              />
            </Styled.Row>

            <Styled.Row>
              <Select
                type="normal"
                id="states"
                name="states"
                isMulti
                placeholder="Estados de atuação"
                formRef={formRef}
                options={states}
                childrenStateUpdater={childrenStateUpdater}
                icon={FiNavigation}
                value={selectedStates}
                onChange={async (
                  selectedOptions: { label: string; value: string }[],
                ) => {
                  setSelectedStates(selectedOptions);
                  formRef.current?.setData({
                    states: selectedOptions,
                  });
                  if (selectedOptions) {
                    const stateIds = selectedOptions.map((option) =>
                      Number(option.value),
                    );
                    const cityPromises = stateIds.map((stateId) =>
                      api.get(`/cities/${stateId}`),
                    );
                    const cityResponses = await Promise.all(cityPromises);
                    const allCities = cityResponses.flatMap((response) =>
                      response.data.map(
                        (city: { id: number; name: string }) => ({
                          label: city.name,
                          value: city.id.toString(),
                        }),
                      ),
                    );

                    setCities(allCities);
                  }
                }}
                hasAnimation
              />
              <Select
                type="normal"
                id="cities"
                name="cities"
                isMulti
                placeholder="Cidades de atuação"
                formRef={formRef}
                options={cities}
                childrenStateUpdater={childrenStateUpdater}
                icon={FiNavigation}
                value={selectedCities}
                onChange={(
                  selectedOptions: { label: string; value: string }[],
                ) => {
                  setSelectedCities(selectedOptions);
                  formRef.current?.setData({
                    cities: selectedOptions,
                  });
                }}
                hasAnimation
              />
            </Styled.Row>

            <Styled.Row>
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Senha"
                icon={FiUnlock}
              />

              <Input
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                placeholder="Confirme a senha"
                icon={FiLock}
              />
            </Styled.Row>

            <div>
              <Button
                type="submit"
                content={isLoading ? "Carregando..." : "Salvar dados"}
                disabled={isLoading}
              />
            </div>
          </Styled.Form>
        </Styled.OperatorContainer>
      </ContainerMain>
    </Container>
  );
};

export default OperatorsById;
