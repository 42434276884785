import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FiRefreshCw, FiPause, FiPlay, FiZoomIn } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";
import { FormHandles } from "@unform/core";
import Swal from "sweetalert2";
// eslint-disable-next-line
import { format } from "date-fns";

import api from "../../services/api";

import { useSidebar } from "../../hooks/SidebarContext";
import { useAuth } from "../../hooks/AuthContext";
import useStateCallback from "../../hooks/UseStateCallback";

import Sidebar from "../../components/Sidebar";
import Button from "../../components/Button";
import Skeleton from "../../components/Skeleton";
import Pagination from "../../components/Pagination";
import BorderlessInput from "../../components/BorderlessInput";
import LoadingSpinner from "../../components/LoadingSpinner";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";
import DetailsModal from "../../components/DetailsModal";
import Modal from "../../components/Modal";

import { formatCurrencyByText } from "../../utils/inputAndTextMasks";
import { formatLocale } from "../../utils/formatLocale";

import { Order } from "../../@types/customTypes";

import * as Styled from "./styles";
import "react-circular-progressbar/dist/styles.css";
import VehiclesSelect from "../../components/VehiclesSelect";
import ButtonV2 from "../../components/ButtonV2";
import apiV3 from "../../services/apiv3";

interface DispatchesList {
  [key: string]: boolean;
}

const DispatchOrders: React.FC = () => {
  const [dispatches, setDispatches] = useState<Order[]>([] as Order[]);
  const [dispatchesString, setDispatchesString] = useStateCallback("");
  const [dispatchesListToSend, setDispatchesListToSend] = useStateCallback<
    null | string[]
  >(null);
  const [selectedVehicles, setSelectedVehicles] = useState([1, 2, 3]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useStateCallback(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useStateCallback<null | Order>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [dispatchesCountdown, setDispatchesCountdown] = useState(120);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentSize, setCurrentSize] = useState<number | null>(null);
  const [debounceTimer, setDebounceTimer] = useState<null | NodeJS.Timeout>(
    null,
  );
  const [dispatchesToDispatch, setDispatchesToDispatch] =
    useState<DispatchesList>({});
  const [query, setQuery] = useState("");
  const [isPaused, setIsPaused] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const getDispatchesInterval = useRef<NodeJS.Timeout | null>(null);
  const dispatchesCountdownInterval = useRef<NodeJS.Timeout | null>(null);

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const { role, company_id } = useAuth();

  const handleGetDispatches = useCallback(
    async (size = 8, page = 1, searchQuery = "") => {
      try {
        setIsLoading(true);
        const { data: dispatchesData } = await api.get(
          `dispatch/orders?page[size]=${size}&page[number]=${page}&search=id:${searchQuery}`,
        );

        dispatchesData.data.forEach((order: Order) => {
          switch (order.order_status_id) {
            case 6:
              order.color = "var(--color-danger)";
              break;
            case 1:
              order.color = "#e5e619";
              break;
            default:
              break;
          }
        });

        setCurrentPage(page);
        setTotalPages(dispatchesData.meta.last_page);
        setDispatches(dispatchesData.data);
        setIsLoading(false);
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast],
  );

  const debounceSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      e.persist();
      const newDebounceTimer = setTimeout(() => {
        handleGetDispatches(currentSize || 8, 1, e.target.value);
        setQuery(e.target.value);
      }, 500);
      setDebounceTimer(newDebounceTimer);
    },
    [debounceTimer, handleGetDispatches, currentSize],
  );

  const handleOpenModal = useCallback(
    (orderId: number) => {
      /*  setDispatchesCountdown(120); */
      const selectedOrder = dispatches?.find((order) => order.id === orderId);
      setCurrentOrder(selectedOrder as Order, () => setIsModalOpen(true));
    },
    [dispatches, setCurrentOrder],
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setCurrentOrder(null);
  }, [setIsModalOpen, setCurrentOrder]);

  const handleSetIsPaused = useCallback(() => {
    setIsPaused((state) => !state);

    if (isPaused) {
      handleGetDispatches();
      getDispatchesInterval.current = setInterval(() => {
        handleGetDispatches();
        addToast("Listagem atualizada!", {
          appearance: "info",
          autoDismiss: true,
        });
      }, 120 * 1000);

      dispatchesCountdownInterval.current = setInterval(() => {
        setDispatchesCountdown((state) => (state !== 0 ? state - 1 : 120));
      }, 1000);
    } else {
      setDispatchesCountdown(120);
      clearInterval(getDispatchesInterval.current as NodeJS.Timeout);
      clearInterval(dispatchesCountdownInterval.current as NodeJS.Timeout);
    }
  }, [isPaused, addToast, handleGetDispatches]);

  const handleSetDispatchesList = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, orderId: number) => {
      setDispatchesToDispatch((state) => ({
        ...state,
        [orderId]: event.target.checked,
      }));

      if (!event.target.checked) {
        setDispatchesToDispatch((state) => {
          delete state[orderId];
          return state;
        });
      }
    },
    [],
  );

  const handleUpdateOrderStatus = useCallback(
    async (newStatus: number, orderId: number) => {
      try {
        setShowSpinner(true);
        await api.put(`orders/${orderId}/status`, {
          order_status_id: newStatus,
        });
        handleGetDispatches(currentSize || 8, currentPage);
        addToast("Status alterado com sucesso!", {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (e) {
        addToast("Ocorreu um erro ao atualizar status do pedido!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
    },
    [handleGetDispatches, addToast, currentSize, currentPage],
  );

  const handleCancelOrder = useCallback(
    async (orderId: number) => {
      const { isConfirmed } = await Swal.fire({
        title: "Tem certeza?",
        text: `Tem certeza que deseja cancelar o despacho ${orderId}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "var(--color-primary)",
        cancelButtonColor: "var(--color-danger)",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (isConfirmed) {
        setDispatchesToDispatch((state) => {
          delete state[orderId];
          return state;
        });
        handleUpdateOrderStatus(6, orderId);
      }
    },
    [handleUpdateOrderStatus],
  );

  const handleCloseConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(false, () =>
      setDispatchesListToSend(null, () =>
        setTimeout(() => setDispatchesString(""), 100),
      ),
    );
  }, [
    setDispatchesString,
    setDispatchesListToSend,
    setIsConfirmationModalOpen,
  ]);

  const handleSaveDispatches = useCallback(async () => {
    try {
      await apiV3.post("/deliveries", {
        company_id,
        vehicles: selectedVehicles,
        orders: dispatchesListToSend,
      });
      handleGetDispatches();
      handleCloseConfirmationModal();
      addToast("Delivery enviado com sucesso!", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (err) {
      addToast("Ocorreu um erro ao salvar despachos!", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  }, [
    addToast,
    company_id,
    handleGetDispatches,
    dispatchesListToSend,
    selectedVehicles,
    handleCloseConfirmationModal,
  ]);

  const handleSendDispatches = useCallback(async () => {
    let tempDispatchesString = "";
    const dispatchesToDispatchLength = Object.keys(dispatchesToDispatch).length;
    const dispatchesList: string[] = [];

    Object.keys(dispatchesToDispatch).forEach((key, index) => {
      if (dispatchesToDispatchLength !== index + 1)
        tempDispatchesString += `${key}, `;
      else tempDispatchesString += `${key}`;

      dispatchesList.push(key);
    });

    setDispatchesString(tempDispatchesString || "1", () =>
      setDispatchesListToSend(dispatchesList, () =>
        setIsConfirmationModalOpen(true),
      ),
    );
  }, [
    setDispatchesListToSend,
    dispatchesToDispatch,
    setDispatchesString,
    setIsConfirmationModalOpen,
  ]);

  useEffect(() => {
    handleGetDispatches();

    getDispatchesInterval.current = setInterval(() => {
      handleGetDispatches();
      addToast("Listagem atualizada!", {
        appearance: "info",
        autoDismiss: true,
      });
    }, 120 * 1000);

    dispatchesCountdownInterval.current = setInterval(() => {
      setDispatchesCountdown((state) => (state !== 0 ? state - 1 : 120));
    }, 1000);

    return () => {
      if (getDispatchesInterval.current) {
        clearInterval(getDispatchesInterval.current);
      }
      if (dispatchesCountdownInterval.current) {
        clearInterval(dispatchesCountdownInterval.current);
      }
    };
  }, [handleGetDispatches, addToast]);

  const renderSelectedVehiclesText = useCallback(() => {
    if (selectedVehicles.length === 3) return "";

    if (selectedVehicles.length === 1) {
      if (selectedVehicles.includes(1))
        return "As entregas serão exibidas APENAS para entregadores que possuam MOTO como veículo.";

      if (selectedVehicles.includes(2))
        return "As entregas serão exibidas APENAS para entregadores que possuam CARRO como veículo.";

      if (selectedVehicles.includes(3))
        return "As entregas serão exibidas APENAS para entregadores que possuam BICICLETA como veículo.";
    }

    if (selectedVehicles.length === 2) {
      if (selectedVehicles.includes(1) && selectedVehicles.includes(2))
        return "As entregas serão exibidas APENAS para entregadores que possuam CARRO e MOTO como veículo.";

      if (selectedVehicles.includes(1) && selectedVehicles.includes(3))
        return "As entregas serão exibidas APENAS para entregadores que possuam MOTO e BICICLETA como veículo.";

      if (selectedVehicles.includes(2) && selectedVehicles.includes(3))
        return "As entregas serão exibidas APENAS para entregadores que possuam CARRO e BICICLETA como veículo.";
    }

    return "";
  }, [selectedVehicles]);

  const filterRecentDispatch = useMemo(() => {
    // eslint-disable-next-line
    const recentDispatch: Order[] = dispatches.filter((dispatch: Order) => {
      return true;
      // return (
      //   format(new Date(dispatch.created_at), "dd/MM/yyyy") ===
      //   format(new Date(), "dd/MM/yyyy")
      // );
    });
    return recentDispatch;
  }, [dispatches]);

  return (
    <Container>
      <Sidebar />
      {showSpinner && <LoadingSpinner />}
      <Modal
        isOpen={isConfirmationModalOpen}
        setIsOpen={handleCloseConfirmationModal}
      >
        <Styled.ModalContent>
          <div className="swal2-icon swal2-warning swal2-icon-show">
            <div className="swal2-icon-content">!</div>
          </div>
          <Styled.ModalTitle>Tem certeza?</Styled.ModalTitle>

          <Styled.ModalH2>Selecione o tipo de veículo:</Styled.ModalH2>
          <VehiclesSelect
            selectedVehicles={selectedVehicles}
            setSelectedVehicles={setSelectedVehicles}
          />

          <Styled.ModalH3>
            Tem certeza que deseja enviar o(s) despacho(s) {dispatchesString}?
          </Styled.ModalH3>
          {selectedVehicles.length !== 3 && (
            <Styled.SelectedVehiclesInfoText>
              <p>{renderSelectedVehiclesText()}</p>
              <p>
                Caso queira maior agilidade e o tamanho do produto seja
                compatível, opte sempre em acionar todos os tipos de veículos.
              </p>
            </Styled.SelectedVehiclesInfoText>
          )}

          <Styled.ActionsContainer
            hasSelectedVehiclesText={selectedVehicles.length !== 3}
          >
            <ButtonV2
              onClick={handleSaveDispatches}
              title="Enviar"
              type="button"
            />
            <ButtonV2
              onClick={handleCloseConfirmationModal}
              title="Cancelar"
              type="button"
              isSecondary
            />
          </Styled.ActionsContainer>
        </Styled.ModalContent>
      </Modal>
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        {isModalOpen && (
          <DetailsModal
            currentOrder={currentOrder as Order}
            setCurrentOrder={setCurrentOrder}
            handleCloseModal={handleCloseModal}
            isModalOpen={isModalOpen}
          />
        )}
        <Styled.DispatchesContainer>
          <Styled.CardTitle
            isAdmin={
              role === process.env.REACT_APP_ADMIN_ROLE ||
              role === process.env.REACT_APP_OPERATOR_ROLE
            }
          >
            <div>
              Listagem de Despachos{" "}
              <FiRefreshCw
                size={20}
                onClick={() =>
                  handleGetDispatches(currentSize || 8, currentPage)
                }
              />
              {isPaused ? (
                <FiPlay size={20} onClick={handleSetIsPaused} />
              ) : (
                <FiPause size={20} onClick={handleSetIsPaused} />
              )}
            </div>
            {/*  <div>A listagem será atualizada periodicamente =)</div> */}
            <Styled.CircularProgress
              value={dispatchesCountdown}
              isPaused={isPaused}
              strokeWidth={12}
              minValue={0}
              maxValue={125}
            />
            {(role === process.env.REACT_APP_ADMIN_ROLE ||
              role === process.env.REACT_APP_OPERATOR_ROLE) && (
              <div>
                <Styled.SearchForm ref={formRef} onSubmit={() => {}}>
                  <BorderlessInput
                    type="text"
                    id="search"
                    name="search"
                    onChange={debounceSearch}
                    placeholder="Pesquisar"
                    autoComplete="off"
                  />
                </Styled.SearchForm>
              </div>
            )}
          </Styled.CardTitle>
          {isLoading ? (
            <Styled.DispatchesShimmer
              isAdmin={
                role === process.env.REACT_APP_ADMIN_ROLE ||
                role === process.env.REACT_APP_OPERATOR_ROLE
              }
            >
              {[...Array(currentSize || 8)].map((skeleton) => (
                <Skeleton key={skeleton} />
              ))}
            </Styled.DispatchesShimmer>
          ) : (
            <Styled.Ul>
              <ReactTooltip />
              {filterRecentDispatch.map((order) => (
                <Styled.Li
                  color={order.color || "lightblue"}
                  key={order.id}
                  isAdmin={
                    role === process.env.REACT_APP_ADMIN_ROLE ||
                    role === process.env.REACT_APP_OPERATOR_ROLE
                  }
                >
                  <Styled.CommonField>
                    <span>ID:</span>
                    <strong>{order.id}</strong>
                  </Styled.CommonField>
                  <Styled.CommonField
                    data-tip={`${order.company.fantasy_name}`}
                  >
                    <span>Estabelecimento:</span>
                    <strong>{order.company.fantasy_name}</strong>
                  </Styled.CommonField>
                  <Styled.CommonField>
                    <span>Data do pedido:</span>
                    <strong>
                      {formatLocale(order.created_at, "dd/MM/yyyy HH:mm")}
                    </strong>
                  </Styled.CommonField>
                  <Styled.CommonField>
                    <span>Destino:</span>
                    <strong>{order.customer.address.neighborhood}</strong>
                  </Styled.CommonField>
                  <Styled.CommonField>
                    <span>Valor:</span>
                    <strong>{formatCurrencyByText(order.amount)}</strong>
                  </Styled.CommonField>
                  <Styled.CommonField data-tip={`${order.customer.name}`}>
                    <span>Cliente:</span>
                    <strong>{order.customer.name.split(" ")[0]}</strong>
                  </Styled.CommonField>

                  <Styled.ActionField>
                    <FiZoomIn
                      size={24}
                      onClick={() => handleOpenModal(order.id)}
                    />
                    <Styled.Input
                      type="checkbox"
                      checked={Boolean(dispatchesToDispatch[order.id])}
                      onChange={(e) => handleSetDispatchesList(e, order.id)}
                      disabled={order.order_status_id === 6}
                    />
                    <Styled.X
                      disabled={order.order_status_id === 6}
                      size={24}
                      onClick={() => handleCancelOrder(order.id)}
                    />
                  </Styled.ActionField>
                </Styled.Li>
              ))}
            </Styled.Ul>
          )}

          {!isLoading && !filterRecentDispatch.length && (
            <Styled.WarningText>
              Não há despachos para envio!
            </Styled.WarningText>
          )}

          <Styled.DispatchButtonContainer>
            <Button
              title="Enviar"
              onClick={handleSendDispatches}
              disabled={!Object.keys(dispatchesToDispatch).length}
            />
          </Styled.DispatchButtonContainer>

          {currentPage !== 0 && (
            <Pagination
              updateList={handleGetDispatches}
              totalPages={totalPages}
              currentPage={currentPage}
              searchQuery={query}
              sizes={[8, 10, 20, 30]}
              currentSize={currentSize}
              setCurrentSize={setCurrentSize}
            />
          )}
        </Styled.DispatchesContainer>
      </ContainerMain>
    </Container>
  );
};

export default React.memo(DispatchOrders);
